import React, { useEffect, useState } from 'react'
import images from './images'
import "./Container.css"
import { ProfileAside, ProfileComp, Search } from './Common/Header'
import _ from "underscore"
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { CustomModal } from './Common/ManageCompany'
import AddTeamMember from './Team/AddTeamMember'
import MailTable from './Mails/MailTable'
import MailDetail from './Mails/MailDetail'
import FiltersMail from './Mails/FiltersMail'
import { DropDown } from './Common'

export const mailContent = {
    cc: [
        "sujeet.karn@gmail.com",
        "girish.narsinghani@gmail.com",
    ],
    attachments: [
        { name: "Spreadsheet.xlsx", type: "xlsx" },
        { name: "Document.pdf", type: "pdf" },
        { name: "Document.pdf", type: "pdf" },
        { name: "Document.pdf", type: "pdf" },
        { name: "Document.pdf", type: "docx" },
        { name: "Document.pdf", type: "docx" },
        { name: "Document.pdf", type: "docx" },
        { name: "Spreadsheet.xlsx", type: "xlsx" },
        { name: "Document.pdf", type: "pdf" },
    ],
    text: `<p>Good Afternoon John,</p>
    <p>Here are your requirements you have asked for. Enclosed with this letter, you will find all the necessary documents and information required for this purpose.</p>    
    <p>Quarterly Files:</p>
    <ol>
    <li>Income and expense statements for each quarter.</li>
    <li>Bank statements for the respective quarters.</li>
    <li>Invoices and receipts for business-related transactions during the quarters.</li>
    <li>Employee payroll details for each quarter.</li>
    <li>Any other relevant financial documents.</li>
    </ol>
    <p>Year-end Return Files:</p>
    <ol>
    <li>Detailed financial statements for the entire fiscal year.</li>
    <li>Audited accounts, if applicable.</li>
    <li>Business tax return information and forms.</li>
    <li>Asset and liability statements.</li>
    <li>Depreciation and amortization schedules.</li>
    <li>Any other relevant tax-related documents.</li>
    </ol>    
    <p>Please review the documents and inform me of any additional information or documentation required to complete the necessary filings accurately and in a timely manner. Additionally, please provide me with guidance on any specific procedures or steps I need to follow to ensure smooth processing.</p>    
    <p>I appreciate your prompt attention to this matter and your continued assistance in managing my financial affairs. Please feel free to contact me at +91 9876543210 or jack.kugerson@gmail.com if you require any further information or clarification.</p>    
    <p>Thank you for your ongoing support and dedication to providing exceptional accounting services.</p>
    <p>Sincerely,</p>    
    <p>Jack Kugerson</p>`
}

export const emailData = {
    lastLogin: "12:55PM, 09 Oct, 2023",
    mails: [
        {
            id: 1,
            sender: {
                "name": "Jack Kugerson",
                "email": "jack.kugerson@gmail.com",
                "color": "#312496"
            },
            mail: {
                id: 1001,
                subject: "Project Initiation required Documents",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023",
                attachments: true
            }
        },
        {
            id: 2,
            sender: {
                "name": "Kadin Herwitz",
                "email": "kadin.herwitz@gmail.com",
                "color": "#1C9386"
            },
            mail: {
                id: 1002,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 3,
            sender: {
                "name": "Emerson Herwitz",
                "email": "emerson.herwitz@gmail.com",
                "color": "#2368B0"
            },
            mail: {
                id: 1003,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 4,
            sender: {
                "name": "Carla Korsgaard",
                "email": "carla.Korsgaard@gmail.com",
                "color": "#30523B"
            },
            mail: {
                id: 1004,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 5,
            sender: {
                "name": "Jack Kugerson",
                "email": "jack.kugerson@gmail.com",
                "color": "#312496"
            },
            mail: {
                id: 1005,
                subject: "Project Initiation required Documents",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 6,
            sender: {
                "name": "Kadin Herwitz",
                "email": "kadin.herwitz@gmail.com",
                "color": "#1C9386"
            },
            mail: {
                id: 1006,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023",
                attachments: true
            }
        },
        {
            id: 7,
            sender: {
                "name": "Emerson Herwitz",
                "email": "emerson.herwitz@gmail.com",
                "color": "#2368B0"
            },
            mail: {
                id: 1007,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 8,
            sender: {
                "name": "Carla Korsgaard",
                "email": "carla.Korsgaard@gmail.com",
                "color": "#30523B"
            },
            mail: {
                id: 1008,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 9,
            sender: {
                "name": "Jack Kugerson",
                "email": "jack.kugerson@gmail.com",
                "color": "#312496"
            },
            mail: {
                id: 1009,
                subject: "Project Initiation required Documents",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 10,
            sender: {
                "name": "Kadin Herwitz",
                "email": "kadin.herwitz@gmail.com",
                "color": "#1C9386"
            },
            mail: {
                id: 1010,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 11,
            sender: {
                "name": "Emerson Herwitz",
                "email": "emerson.herwitz@gmail.com",
                "color": "#2368B0"
            },
            mail: {
                id: 1011,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
        {
            id: 12,
            sender: {
                "name": "Carla Korsgaard",
                "email": "carla.Korsgaard@gmail.com",
                "color": "#30523B"
            },
            mail: {
                id: 1012,
                subject: "Request for the project Quotation",
                description: "nytivis. Biojörade mäng mor heteroment pana spesk dokahet emedan paragt. Kans nism nissa disk os rirad dian, och ponade.",
                date: "12:55PM, 09 Sep, 2023"
            }
        },
    ]
}

export const team = {
    "A": [
        {
            id: 100,
            "name": "Aditya Dutta",
            "position": "Accountant",
            "size": "332.5GB",
            "mail": "110.5GB",
            "file": "222GB",
            "phone": "+91 9876543210",
            "email": "aditya.dutta@gmail.com",
            "color": "#007BFF",
            "data": emailData
        },
        {
            id: 101,
            "name": "Anurag Upadhayay",
            "position": "Accountant",
            "size": "120GB",
            "mail": "90GB",
            "file": "30GB",
            "phone": "+91 9876543210",
            "email": "anurag.upadhayay@gmail.com",
            "color": "#039000",
            "data": emailData
        },
        {
            id: 102,
            "name": "Ashwini Bhadane",
            "position": "Accountant",
            "size": "220GB",
            "mail": "100GB",
            "file": "120GB",
            "phone": "+91 9876543210",
            "email": "ashwini.bhadane@gmail.com",
            "color": "#F15A29",
            "data": emailData
        }
    ],
    "B": [
        {
            id: 103,
            "name": "Bhuvanesvari",
            "position": "Human Resource",
            "size": "520GB",
            "mail": "100GB",
            "file": "420GB",
            "phone": "+91 9876543210",
            "email": "bhuvaneswari@gmail.com",
            "color": "#bhuvaneswari",
            "data": emailData
        }
    ],
    "G": [
        {
            id: 104,
            "name": "Girish Narsinghani",
            "position": "Chartered Accountant",
            "size": "180GB",
            "mail": "110.5GB",
            "file": "69.5GB",
            "phone": "+91 9876543210",
            "email": "girish.narsinghani@gmail.com",
            "color": "#0D0D82",
            "data": emailData
        }
    ],
    "K": [
        {
            id: 105,
            "name": "Kajal Tejwani",
            "position": "Chartered Accountant",
            "size": "50GB",
            "mail": "35GB",
            "file": "15GB",
            "phone": "+91 9876543210",
            "email": "kajal.tejwani@gmail.com",
            "color": "#7252B7",
            "data": emailData
        },
        {
            id: 106,
            "name": "Kashmira Paghare",
            "position": "Chartered Accountant",
            "size": "20GB",
            "mail": "5GB",
            "file": "15GB",
            "phone": "+91 9876543210",
            "email": "kashmira.paghare@gmail.com",
            "color": "#FFA121",
            "data": emailData
        }
    ],
    "R": [
        {
            id: 107,
            "name": "Reema Ahuja",
            "position": "Chartered Accountant",
            "size": "100GB",
            "mail": "20GB",
            "file": "80GB",
            "phone": "+91 9876543210",
            "email": "reema.ahuja@gmail.com",
            "color": "#0D0D82",
            "data": emailData
        }
    ],
    "S": [
        {
            id: 108,
            "name": "Sujeet Karn",
            "position": "Manager",
            "size": "550GB",
            "mail": "150GB",
            "file": "400GB",
            "phone": "+91 9876543210",
            "email": "sujeet.karn@gmail.com",
            "color": "#F15A29",
            "data": emailData
        }
    ],
    "T": [
        {
            id: 109,
            "name": "Tarun Gupta",
            "position": "Article",
            "size": "20GB",
            "mail": "2GB",
            "file": "18GB",
            "phone": "+91 9876543210",
            "email": "tarun.gupta@gmail.com",
            "color": "#039000",
            "data": emailData
        },
        {
            id: 110,
            "name": "Tanuj Verma",
            "position": "Accountant",
            "size": "80GB",
            "mail": "10GB",
            "file": "70GB",
            "phone": "+91 9876543210",
            "email": "tanuj.verma@gmail.com",
            "color": "#7252B7",
            "data": emailData
        }
    ]
}

const folderData = [
    {
        id: 1, type: "folder", name: "NCS App", size: "1.1GB", modified: "20 April, 2023", data: []
    },
    {
        id: 2, type: "folder", name: "eOxegen", size: "3.5GB", modified: "18 April, 2023", data: []
    },
    {
        id: 3, type: "folder", name: "ERPCA App", size: "2.1GB", modified: "17 April, 2023", data: []
    },
    { id: 4, type: "file", name: "Document no.1.pdf", size: "3.5MB", modified: "20 April, 2023", shareTo: "jack.kugerson@gmail.com." },
    { id: 5, type: "file", name: "Document no.2.pdf", size: "1.5MB", modified: "20 April, 2023", shareTo: "jack.kugerson@gmail.com." },
    { id: 6, type: "file", name: "Document no.3.pdf", size: "2MB", modified: "20 April, 2023", shareTo: "mack.kugerson@gmail.com." },

]
export const filesData = [
    {
        id: 1, type: "folder", name: "", size: "2.1GB", modified: "17 April, 2023", data: [
            {
                id: 1, type: "folder", name: "Assets", size: "1.1GB", modified: "19 April, 2023", data: folderData
            },
            {
                id: 2, type: "folder", name: "ERPCA App", size: "3.5GB", modified: "18 April, 2023", data: folderData
            },
            {
                id: 3, type: "folder", name: "eOxegen", size: "5GB", modified: "17 April, 2023", data: folderData
            },
            {
                id: 4, type: "folder", name: "NCP App", size: "2GB", modified: "17 April, 2023", data: folderData
            },
            {
                id: 5, type: "folder", name: "Records", size: "5GB", modified: "20 March, 2023", data: folderData
            }
        ]
    }
]



export default function Container(props) {
    let menu = [
        { name: "Mails", link: `/${props.type}/mails`, hide: false },
        { name: "Attachments", link: `/${props.type}/attachments`, hide: false },
        { name: "Files", link: `/${props.type}/files`, hide: props.type == "contacts" ? true : false },
    ]
    const sortBy = [
        { label: "Sort by Alphabetic", icon: images?.sort_by_alpha, value: "Alphabetic" },
        { label: "Sort by Size", icon: images?.sort_filter_list, value: "Size" },
    ]
    const filesDropdown = [
        { label: "Open", icon: images?.folder_open, value: "Open" },
        { label: "Download", icon: images?.download3, value: "Download" },
        { label: "Details", icon: images?.info, value: "Details" },
    ]

    const searchParams = new URLSearchParams(window.location.search)
    const param = useParams()
    let navigate = useNavigate()
    const location = useLocation()
    const [showSidebar, setshowSidebar] = useState(false)
    const [teamData, setTeamData] = useState(team)
    const [selectedKey, setSelectedKey] = useState({})
    const [selected, setSelected] = useState({})
    const [mailData, setMailData] = useState({})
    const [showAside, setshowAside] = useState(false)
    const [aside, setAside] = useState({})
    const [gridView, setGridView] = useState(true)
    const [showOption, setShowOption] = useState(false)
    const [filter, setFilter] = useState({
        show: false
    })
    const [activeFolder, setActiveFolder] = useState(filesData[0] || {})
    const [previousFolder, setPreviousFolder] = useState({})

    const [showDD, setShowDD] = useState("")

    const [sortValue, setsortValue] = useState(sortBy[0] || {})
    const [openMail, setopenMail] = useState(false)

    const [children, setChildren] = useState({})
    const [modal, setModal] = useState({
        size: "md",
        show: false
    })
    const fileOptionClick = (opt, data) => {
        if (opt?.value == "Open") {
            if (data.data.length > 0) {
                let prev = { ...activeFolder }
                setPreviousFolder(prev)
                setActiveFolder(data)
                handleClose()
            }
        }
        if (opt?.value == "Details") {
            setshowAside(true)
            setAside({
                ...aside,
                type: "files",
                title: "Details",
                showHeader: true,
                data: data
            })
        }
    }

    const filterOnclick = (type) => { }

    const clickedTR = (data) => {
        let prev = { ...activeFolder }
        setPreviousFolder(prev)
        setActiveFolder(data)
    }
    const handleClose = () => {
        setModal({ ...modal, show: false })
        setAside({})
        setshowAside(false)
    }
    const handleSort = (val) => {
        setsortValue(val)
    }

    function handleMailClick(data) {
        setopenMail(false)
        let obj = { ...teamData }
        Object.keys(obj).map((keys) => {
            obj[selectedKey].map((opt) => {
                if (opt?.email == selected?.email) {
                    let arr = []
                    opt.data.mails.map((val) => {
                        if (val.id == data.id) {
                            arr.push({ ...val, read: true })
                        } else {
                            arr.push({ ...val })
                        }
                    })
                    console.log("arr", arr)
                    opt.data.mails = arr
                }
                console.log("opt", opt)
            })
        })
        setTeamData(obj)
    }

    function handleSelected(data, keys) {
        let obj = { ...teamData }
        Object.keys(obj).map((keys) => {
            obj[keys].map((opt) => {
                if (opt?.email == selected?.email) {
                    let arr = []
                    opt.data.mails.map((val) => {
                        delete val["read"]
                        arr.push({ ...val })
                    })
                    console.log("arr", arr)
                    opt.data.mails = arr
                }
                console.log("opt", opt)
            })
        })
        setTeamData(obj)

        setSelected(data)
        setSelectedKey(keys)
    }

    const showAddMember = () => {
        setModal({ ...modal, show: true })
        setChildren(
            <AddTeamMember handleClose={handleClose} />
        )

        if (searchParams.get("type")) {
            searchParams.delete("type")
            const to = { pathname: location.pathname, search: searchParams.toString() };
            navigate(to, { replace: true });
        }
    }

    const clicked = (data) => {
        console.log("clicked data", data)
        setShowDD(data)
    }
    const goBack = () => {
        setActiveFolder(previousFolder)
        setPreviousFolder({})
    }

    useEffect(() => {
        setShowOption(false)
    }, [param.id])


    useEffect(() => {
        if (searchParams.get("type")) {
            showAddMember()
        }
    }, [searchParams.get("type")])

    console.log("showDD: ", showDD)
    console.log("selected: ", selected)
    console.log("mailData: ", mailData)
    console.log("activeFolder: ", activeFolder)
    console.log("previousFolder: ", previousFolder)
    return (
        <div className='auth_container auth_container_fluid2'>
            <div className='auth_team_container'>
                <div className='auth_team_aside_settings d-sm-block d-none'>
                    <button className='ath_aside_setting_btns' onClick={showAddMember}>
                        <img src={images?.person_add} altr="" height={12} />
                    </button>
                    <NavLink to="/team/settings" className='ath_aside_setting_btns'>
                        <img src={images?.settings} altr="" height={15} />
                    </NavLink>
                </div>

                <div className='auth_team_wrapper white-bg'>
                    <button className='aside_toggle' onClick={() => setshowSidebar(!showSidebar)}>
                        <img src={images?.toggle} alt="" />
                    </button>
                    <div className='row gx-0'>
                        <div className={`col-sm-4 auth_team_wrapper_aside ${showSidebar ? "show" : ""}`}>
                            <div className="auth_team_left_aside">
                                <div className='auth_team_left_header'>
                                    <h5 className='font16 sbold primary-text mb-0'>{props.title}</h5>
                                    <div className='d-flex w-100 align-items-center mt-sm-0 mt-2'>
                                        <div className='mx-3 w-100 ms-0 ms-sm-3'>
                                            <Search placeholder={`Search ${props.type == "contacts" ? props.title : "Team"}`} />
                                        </div>
                                        <div className='position-relative'>
                                            <button onClick={() => setShowDD("sort")}>
                                                <img src={images?.sort} width={16} alt="" />
                                            </button>
                                            {showDD == "sort" ?
                                                <>
                                                    <div className='custom_dropdown_backdrop' onClick={() => setShowDD("")}></div>
                                                    <div className='custom_dropdown_list_outer left' onClick={() => setShowDD("")}>
                                                        <div className='custom_dropdown_list'>
                                                            {sortBy.map((opt, i) => {
                                                                return (
                                                                    <button key={i} onClick={() => fileOptionClick(opt)} className='font10'>
                                                                        {opt?.icon ?
                                                                            <span className='list_icon'>
                                                                                <img src={opt?.icon} alt="" className='me-2' />
                                                                            </span>
                                                                            : null
                                                                        }
                                                                        {opt?.label}
                                                                    </button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='auth_team_list'>
                                    {
                                        Object.keys(teamData).map((keys) => {
                                            return (
                                                <div key={keys}>
                                                    <p className='team_group font12'><span>{keys}</span></p>
                                                    <div className='auth_team_group'>
                                                        {teamData[keys].map((data, i) => {
                                                            return (
                                                                <NavLink to={`/${props.type}/mails`} className={`auth_team_group_item ${selected?.id == data?.id ? "selected" : ""}`} key={i} onClick={() => {
                                                                    handleSelected(data, keys)
                                                                    setMailData({})
                                                                    setshowSidebar(false)
                                                                }}>
                                                                    <div className='auth_team_badge' style={{ backgroundColor: data?.color }}>
                                                                        {data.name.split(" ")[0].charAt(0).toUpperCase()}{data.name.split(" ")[1] ? data.name.split(" ")[1].charAt(0).toUpperCase() : data.name.split(" ")[0].charAt(1).toUpperCase()}
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='row align-items-end'>
                                                                            <div className='col-xl-6'>
                                                                                <p className='font12 text-dark mb-0'>{data.name}</p>
                                                                                <p className='font12'>{data.position}</p>
                                                                            </div>
                                                                            <div className='col-xl-6 text-xl-end'>
                                                                                <p className='font10 mb-0'>{data.size}</p>
                                                                                <p className='font10'>{data.email}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-8' onClick={() => setshowSidebar(false)}>
                            <div className='auth_team_right_aside'>
                                {_.isEmpty(selected) ?
                                    <div className='check_team_email'>
                                        <div className='check_team_email_content text-center'>
                                            <img src={props.type == "contacts" ? images?.empty_contacts : images?.check_email} alt="" width={352} className='img-fluid' />
                                            {props.type == "contacts" ?
                                                <>
                                                    <h5 className='font14 primary-text sbold mt-3 mb-1'>Check your Clients Emails</h5>
                                                    <p className='font12'>You can check through Clients mails with your Team</p>
                                                </>
                                                :
                                                <>
                                                    <h5 className='font14 primary-text sbold mt-3 mb-1'>Check your Teammates Emails</h5>
                                                    <p className='font12'>You can check through Team mails with your Clients</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='selected_team_mails'>
                                        <div className='selected_team_header'>
                                            <div className='selected_team_header_inner' onClick={() => setShowOption(false)}>
                                                <button className='team_personal_info text-start' onClick={() => setshowAside(true)}>
                                                    <div className='auth_team_badge sm' style={{ backgroundColor: selected?.color }}>
                                                        {selected.name.split(" ")[0].charAt(0).toUpperCase()}{selected.name.split(" ")[1] ? selected.name.split(" ")[1].charAt(0).toUpperCase() : selected.name.split(" ")[0].charAt(1).toUpperCase()}
                                                    </div>
                                                    <div className='w-100'>
                                                        <p className='font12 text-dark mb-0'>{selected.name}
                                                            <img src={images?.arrow_drop_down} alt="" width={4.5} className='ms-2' />
                                                        </p>
                                                        <p className='font10'>Last Login {selected.data?.lastLogin}</p>
                                                    </div>
                                                </button>
                                            </div>

                                            <button className='close_options d-block d-sm-none px-3' onClick={() => setShowOption(!showOption)}>
                                                <img src={images?.dots} alt="" height={15} />
                                            </button>
                                            <div className={`auth_header_nav mx-4 ${showOption ? "show" : ""}`}>
                                                {menu.filter((x)=>!x?.hide).map((nav) => {
                                                    return (
                                                        <NavLink key={nav.name} to={nav?.link} className="nav_link" onClick={() => setFilter({ ...filter, show: false })}>
                                                            {nav?.name}
                                                        </NavLink>
                                                    )
                                                })}
                                            </div>
                                            <Search placeholder='Search Mails' endIcon={filter?.show ? false : true} icon={images?.filter_alt} onclick={() => setFilter({ ...filter, show: true })} />
                                        </div>
                                        <div onClick={() => setShowOption(false)}>
                                            {/* param mail */}
                                            {param.id && (param.id == "mails" || param.id == "attachments") &&
                                                <>
                                                    {param.id == "attachments" ?
                                                        <>
                                                            <hr />
                                                            <div className='px-3 row align-items-center justify-content-between'>
                                                                <div className="col-md-4 mb-md-0 mb-2">
                                                                    <h5 className='font12 mb-0'>Mails & Attachments</h5>
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className='d-flex align-items-center justify-content-md-end' style={{ whiteSpace: "nowrap" }}>
                                                                        <div className='storage_bar_outer' style={{ width: 100, flex: "0 0 100px" }}>
                                                                            <span style={{ width: "20%", backgroundColor: "#FF7020" }}></span>
                                                                        </div>
                                                                        <p className="m-0 mx-3">{selected?.size} used / 690.0GB</p>
                                                                        <button className='font12 med'><img className='me-2' src={images?.download2} alt='' /></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                    {!_.isEmpty(mailData) ?
                                                        <MailDetail
                                                            mailData={mailData}
                                                            setMailData={setMailData}
                                                            selected={selected}
                                                            mailContent={mailContent}
                                                            openMail={openMail}
                                                            setopenMail={setopenMail}
                                                        />
                                                        :
                                                        <div>
                                                            {filter.show ?
                                                                <FiltersMail
                                                                    selected={selected}
                                                                    filter={filter}
                                                                    setFilter={setFilter}
                                                                    onclick={filterOnclick}
                                                                />
                                                                : null
                                                            }
                                                            <MailTable
                                                                setMailData={setMailData}
                                                                mailContent={mailContent}
                                                                handleMailClick={handleMailClick}
                                                                selected={selected}
                                                            />
                                                        </div>
                                                    }
                                                </>
                                            }

                                            {param.id == "files" ?
                                                <>
                                                    <hr />
                                                    <div className='px-3 row align-items-center justify-content-between'>
                                                        <div className="col-md-4 mb-md-0 mb-2">
                                                            {activeFolder.name !== "" && !_.isEmpty(previousFolder) ?
                                                                <h5 className='font12 mb-0'>
                                                                    <button onClick={goBack}>
                                                                        <img className='me-2' src={images?.arrow_back} alt='' width={15} />
                                                                        {previousFolder.name || "Files"} / {activeFolder.name}</button></h5>
                                                                :
                                                                <h5 className='font12 mb-0'>Mails & Attachments</h5>
                                                            }
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className='d-block d-sm-flex align-items-center justify-content-md-end' style={{ whiteSpace: "nowrap" }}>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className='storage_bar_outer' style={{ width: 100, flex: "0 0 100px" }}>
                                                                        <span style={{ width: "20%", backgroundColor: "#FF7020" }}></span>
                                                                    </div>
                                                                    <p className="m-0 mx-3">{selected?.size} used / 690.0GB</p>
                                                                </div>
                                                                <div className='d-flex mt-sm-0 mt-3'>
                                                                    <p className="m-0 ms-0 ms-sm-3 me-2">Sort By</p>
                                                                    <DropDown
                                                                        selected={sortValue}
                                                                        list={sortBy}
                                                                        onclick={handleSort}

                                                                    />
                                                                    <button className='font12 med ms-1' onClick={() => setGridView(!gridView)}>
                                                                        <img className='me-2' src={gridView ? images?.grid_view : images?.view_list} alt='' />
                                                                    </button>
                                                                    <button className='font12 med ms-1'>
                                                                        <img className='me-2' src={images?.download2} alt='' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    {gridView ?
                                                        <div className='team_files_read p-3'>
                                                            {activeFolder.data.map((files, i) => {
                                                                return (
                                                                    <div key={i} className=' position-relative mb-4'>
                                                                        <button className='font10 me-4' onClick={() => clicked(files)}>
                                                                            <div>
                                                                                <img src={files.type == "folder" ? images?.folder : images?.notes} alt="" className='mb-2' />
                                                                            </div>
                                                                            {files.name}
                                                                        </button>
                                                                        {showDD.id == files.id ?
                                                                            <>
                                                                                <div className='custom_dropdown_backdrop' onClick={() => setShowDD("")}></div>
                                                                                <div className='custom_dropdown_list_outer left' onClick={() => setShowDD("")}>
                                                                                    <div className='custom_dropdown_list'>
                                                                                        {filesDropdown.map((opt, i) => {
                                                                                            return (
                                                                                                <button key={i} onClick={() => fileOptionClick(opt, files)} className='font10'>
                                                                                                    {opt?.icon ?
                                                                                                        <span className='list_icon'>
                                                                                                            <img src={opt?.icon} alt="" className='me-2' />
                                                                                                        </span>
                                                                                                        : null
                                                                                                    }
                                                                                                    {opt?.label}
                                                                                                </button>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        :
                                                        <div className='team_files_read p-3'>
                                                            <table className='table'>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className='font12 text-light'>Name</td>
                                                                        <td className='font12 text-light'>Last Modified</td>
                                                                        <td className='font12 text-light'>Owner</td>
                                                                        <td className='font12 text-light'>Size</td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody>
                                                                    {activeFolder.data.map((files, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    <button className='w-100 d-flex align-items-center font10 me-4' onClick={() => () => files.data.length > 0 ? clickedTR(files) : null}>
                                                                                        <div>
                                                                                            <img src={files.type == "folder" ? images?.folder : images?.notes} alt="" width={19} className='me-2' />
                                                                                        </div>
                                                                                        {files.name}
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <p className='font12 text-light'>{files?.modified}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className='font12 text-light'>{selected?.email}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className='font12 text-light'>{files?.size}</p>
                                                                                </td>
                                                                                <td width={20}>
                                                                                    <button className='font12 med'><img className='me-2' src={images?.download3} alt='' /></button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                </>
                                                : null
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {showAside ?
                    <ProfileAside close={() => setshowAside(false)} showHeader={aside.showHeader || false} title={aside.title}>
                        {aside.type == "files" ?
                            <>
                                <div className='aside_profile_body'>
                                    <div className='aside_profile_img mb-3'>
                                        <div className='font10 mb-4'>
                                            <div>
                                                <img src={aside.data.type == "folder" ? images?.folder : images?.notes} alt="" width={70} className='mb-4' />
                                            </div>
                                            <h5 className='font16 reg'>{aside.data.name}</h5>
                                        </div>
                                    </div>
                                    <div className='my-4'>
                                        <div className='d-flex justify-content-between'>
                                            <p className='font12 text-light'>Owner</p>
                                            <p className='font12'><span className='text-dark'>{selected?.email}</span></p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <p className='font12 text-light'>Last Modified</p>
                                            <p className='font12'><span className='text-dark'>{aside.data.modified}</span></p>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <p className='font12 text-light'>Folder Size</p>
                                            <p className='font12'><span className='text-dark'>{aside.data.size}</span></p>
                                        </div>
                                    </div>
                                    <div className='logout_btns'>
                                        <button onClick={() => fileOptionClick({ value: "Open" }, aside.data)}>
                                            <span className='list_icon'>
                                                <img src={images?.folder_open} alt="" className='me-2' />
                                            </span>
                                            Open
                                        </button>
                                        <button onClick={() => fileOptionClick({ value: "Download" }, aside.data)}>
                                            <span className='list_icon'>
                                                <img src={images?.download3} alt="" className='me-2' />
                                            </span>
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <ProfileComp profile={selected} type="" />
                                <div className='recipent_search'>
                                    <p className='med'>{selected?.name ? `${selected?.name.split(" ")[0]}'s Recipients` : ""}</p>
                                    <div className='my-2'>
                                        <Search placeholder="Search Recipients" />
                                    </div>
                                    <div className='logout_btns'>
                                        {selected?.data.mails.map((opt, i) => {
                                            return (
                                                <button key={i}>
                                                    <span className='recipent_badge' style={{ backgroundColor: opt?.sender?.color }}>{opt?.sender?.name.split(" ")[0].charAt(0).toUpperCase()}</span>
                                                    {opt?.sender?.name}
                                                </button>
                                            )
                                        })}
                                    </div>
                                </div>
                            </>
                        }
                    </ProfileAside>
                    : null
                }
                <CustomModal modal={modal} handleClose={handleClose} children={children} />
            </div >
        </div >
    )
}
