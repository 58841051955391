import React, { useEffect, useState } from 'react'
import MailDetail from '../Mails/MailDetail'
import { emailData, mailContent } from '../Container'
import MailTable from '../Mails/MailTable'
import _ from "underscore"
import { useNavigate, useParams } from 'react-router-dom'
import images from '../images'
import Dropdown from '../Common/Dropdown'

const filters = [
    { label: "Sort By Lowest", value: "Sort By Lowest", icon: images?.signal_cellular_alt },
    { label: "Sort By Largest", value: "Sort By Largest", icon: images?.signal_cellular_alt },
]

export default function LargeMails() {
    const navigate = useNavigate()
    const param = useParams()
    const [selected, setSelected] = useState({ data: emailData } || {})
    const [mailData, setMailData] = useState({})
    const [showDD, setShowDD] = useState("")

    const handleMailClick = () => {

    }
    const optionClick = (opt) => {
        setShowDD("")
    }

    console.log("report selected", selected)
    return (
        <div className='auth_container_fluid'>
            <div className='header_container'>
                <div className='row justify-content-center'>
                    <div className='col-md-8'>
                        <div className='card border-0 p-3 custom_card'>
                            <div className='auth_team_left_header justify-content-between mb-3'>
                                <h5 className='mb-0'>
                                    <button onClick={() => navigate(-1)} className='font16 bold mb-0 primary-text'>
                                        <img className='me-2' src={images?.arrow_back} alt='' width={15} />
                                        Large Size Mails
                                    </button>
                                </h5>
                                <span className='position-relative'>
                                    <button onClick={() => setShowDD("sort")}>
                                        <img src={images?.sort} width={16} alt="" />
                                    </button>
                                    {showDD == "sort" ?
                                        <Dropdown
                                            close={() => setShowDD("")}
                                        >
                                            {filters.map((opt, i) => {
                                                return (
                                                    <button key={i} onClick={() => optionClick(opt)} className='font10'>
                                                        {opt?.icon ?
                                                            <span className='list_icon'>
                                                                <img src={opt?.icon} alt="" className='me-2' />
                                                            </span>
                                                            : null
                                                        }
                                                        {opt?.label}
                                                    </button>
                                                )
                                            })}
                                        </Dropdown>
                                        : null
                                    }
                                </span>
                            </div>
                            {!_.isEmpty(mailData) ?
                                <MailDetail
                                    mailData={mailData}
                                    setMailData={setMailData}
                                    selected={selected}
                                    mailContent={mailContent}
                                    openMail={true}
                                />
                                :
                                <MailTable
                                    setMailData={setMailData}
                                    mailContent={mailContent}
                                    handleMailClick={handleMailClick}
                                    selected={selected}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
