import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import images from './components/private/images';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SnackbarProvider autoHideDuration={3000} iconVariant={{
    success: <svg width="19" height="18" viewBox="0 0 19 18" className='me-2' fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0001 17.5007H5.66675V6.66732L11.5001 0.833984L12.5417 1.87565C12.639 1.97287 12.7188 2.10482 12.7813 2.27148C12.8438 2.43815 12.8751 2.59787 12.8751 2.75065V3.04232L11.9584 6.66732H16.5001C16.9445 6.66732 17.3334 6.83398 17.6667 7.16732C18.0001 7.50065 18.1667 7.88954 18.1667 8.33398V10.0007C18.1667 10.0979 18.1563 10.202 18.1355 10.3132C18.1147 10.4243 18.0834 10.5284 18.0417 10.6257L15.5417 16.5007C15.4167 16.7784 15.2084 17.0145 14.9167 17.209C14.6251 17.4034 14.3195 17.5007 14.0001 17.5007ZM4.00008 6.66732V17.5007H0.666748V6.66732H4.00008Z" fill="#0D0D82" />
    </svg>
  }}
  >
    <App />
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
