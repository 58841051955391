import React, { useState } from 'react'
import images from '../images'
import { NavLink } from 'react-router-dom'
import { logout } from '../../../App'

const profile = {
    name: "John doe",
    email: "john.doe@gmail.com",
    phone: "+91 9876543210",
    size: "332.5GB",
    mail: "110.5GB",
    file: "222GB",
}
export function Search(props) {
    return (
        <div className='auth_search' style={props.style}>
            <button className='search_icon'>
                <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.13322 9.69995C3.88279 9.69995 2.82007 9.26245 1.94507 8.38745C1.07007 7.51245 0.632568 6.44995 0.632568 5.19995C0.632568 3.94995 1.07007 2.88745 1.94507 2.01245C2.82007 1.13745 3.88257 0.699951 5.13257 0.699951C6.38257 0.699951 7.44507 1.13745 8.32007 2.01245C9.19507 2.88745 9.63257 3.95017 9.63257 5.20061C9.63257 5.70537 9.55444 6.18145 9.39819 6.62885C9.24194 7.07625 9.02632 7.48745 8.75132 7.86245L12.7638 11.875C12.9013 12.0125 12.9701 12.1687 12.9701 12.3437C12.9701 12.5187 12.9013 12.675 12.7638 12.8125C12.6263 12.95 12.4669 13.0187 12.2857 13.0187C12.1044 13.0187 11.9451 12.95 11.8076 12.8125L7.79507 8.8187C7.42007 9.0937 7.00887 9.30933 6.56147 9.46558C6.11407 9.62183 5.63799 9.69995 5.13322 9.69995ZM5.13257 8.34995C6.00757 8.34995 6.75132 8.0437 7.36382 7.4312C7.97632 6.8187 8.28257 6.07495 8.28257 5.19995C8.28257 4.32495 7.97632 3.5812 7.36382 2.9687C6.75132 2.3562 6.00757 2.04995 5.13257 2.04995C4.25757 2.04995 3.51382 2.3562 2.90132 2.9687C2.28882 3.5812 1.98257 4.32495 1.98257 5.19995C1.98257 6.07495 2.28882 6.8187 2.90132 7.4312C3.51382 8.0437 4.25757 8.34995 5.13257 8.34995Z" fill="#898989" />
                </svg>
            </button>
            <input type='text' placeholder={props.placeholder} className='search_input' />
            {props.endIcon ?
                <button onClick={props.onclick}>
                    <img src={props.icon} alt="" />
                </button>
                : null
            }
        </div>
    )
}

export function ProfileComp(props) {
    return (
        <div className='aside_profile_body'>
            <div className='aside_profile_img mb-3'>
                {props.type == "profile" ?
                    <img src={images.profile} alt="" width={100} />
                    :
                    <div className='auth_team_badge large' style={{ backgroundColor: props.profile?.color }}>
                        {props.profile.name.split(" ")[0].charAt(0).toUpperCase()}{props.profile.name.split(" ")[1] ? props.profile.name.split(" ")[1].charAt(0).toUpperCase() : props.profile.name.split(" ")[0].charAt(1).toUpperCase()}
                    </div>
                }
            </div>
            <h5 className='font16 reg'>{props.profile?.name}</h5>
            <p className='font12 mb-1'>{props.profile?.email}</p>
            <p className='font12'>{props.profile?.phone}</p>
            <p className='font12'>Last Login {props.profile?.lastLogin}</p>
            <div className='my-4'>
                <div className='d-flex justify-content-between'>
                    <p className='font12 primary-text'>Storage Used</p>
                    <p className='font12'><span className='text-dark'>{props.profile?.size}</span> used / 690.0GB</p>
                </div>
                <div className='storage_bar_outer'>
                    <span style={{ width: "50%", backgroundColor: "#FF7020" }}></span>
                    <span style={{ width: "20%", backgroundColor: "#007BFF" }}></span>
                </div>
                <div className='storage_values'>
                    <p className='font12 text-dark'>
                        <span><span style={{ backgroundColor: "#007BFF" }} className='storage_value_dot'></span>Mail</span>
                        <span>{props.profile?.mail}</span>
                    </p>
                    <p className='font12 text-dark'>
                        <span><span style={{ backgroundColor: "#FF7020" }} className='storage_value_dot'></span>File</span>
                        <span>{props.profile?.file}</span>
                    </p>
                </div>
                {props.type == "profile" ?
                    <div className='logout_btns'>
                        <NavLink to="/device-loggedin" onClick={props.close}>
                            <img src={images?.devices} alt="" className='me-2' />
                            Device’s Signed in
                        </NavLink>
                        <button onClick={() => logout()}>
                            <img src={images?.logout} alt="" className='me-2' />
                            Logout
                        </button>
                    </div>
                    :
                    <div className=''></div>
                }
            </div>
        </div>
    )
}
export function CompanyProfileComp(props) {
    return (
        <div className='aside_profile_body'>
            <div className='aside_profile_img mb-3'>
                <img src={images.profile_logo} alt="" width={100} />
            </div>
            <h5 className='font16 reg'>John Associates & Co.</h5>
            <div className='my-4'>
                <NavLink to="/manage-company" onClick={props.close} className={"common_btn primary-bg w-100 rounded"}>Manage Company</NavLink>
                <NavLink to="/" onClick={props.close} className={"common_btn w-100 rounded mt-3"}>+ Add Company</NavLink>
            </div>
            <div className='recipent_search'>
                <p className='med'>Switch Company</p>
                <div className='my-2'>
                    <Search placeholder="Search Recipients" />
                </div>
                <div className='logout_btns'>
                    <button>
                        <span className='recipent_badge' style={{ backgroundColor: "#312496" }}>D</span>
                        Devs & Designs
                    </button>
                </div>
            </div>
        </div>
    )
}

export function ProfileAside(props) {
    return (
        <div className='aside_outer'>
            <div className='aside_backdrop' onClick={props.close}></div>
            <div className='profile_aside'>
                {props.showHeader ?
                    <div className='aside_header'>
                        <h5 className='mb-0 font16 primary-text'>{props.title}</h5>
                        <button onClick={props.close}><img src={images.close} alt="x" /></button>
                    </div>
                    :
                    <button onClick={props.close} style={{ position: "absolute", right: "30px", top: "50px" }}><img src={images.close} alt="x" /></button>
                }
                <div className='aside_body'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export function Header() {
    let menu = [
        { name: "Team", icon: images?.team, link: "/team" },
        { name: "Contacts", icon: images?.contacts, link: "/contacts" },
        { name: "Report", icon: images?.report, link: "/reports" },
        { name: "Analytics", icon: images?.analytic, link: "/analytic" },
    ]
    const [showAside, setshowAside] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [asideData, setAsideData] = useState({
        title: "",
        type: ""
    })
    const openAside = (type, title) => {
        setAsideData({ ...asideData, title: title, type: type })
        setshowAside(true)
        setShowOptions(false)
    }
    const closeAside = () => {
        setAsideData({ ...asideData, title: "", type: "" })
        setshowAside(false)
        setShowOptions(false)
    }
    return (
        <div className='auth_header_outer auth_container_fluid'>
            <div className='header_container'>
                <div className='auth_header_inner'>
                    <NavLink to={"/team"} className={"m-0"}>
                        <img src={images?.logo} alt="Mailinsure" width={83} />
                    </NavLink>
                    <div className='auth_header_nav'>
                        {menu.map((nav) => {
                            return (
                                <NavLink className={"nav_link"} key={nav.name} to={nav?.link}>
                                    <img src={nav.icon} alt={nav.name} width={15} />
                                    {nav?.name}
                                </NavLink>
                            )
                        })}
                    </div>
                    <button className='d-block d-sm-none' onClick={() => setShowOptions(true)}>
                        <img src={images?.toggle} alt="" width={20} />
                    </button>
                    <div className={`header_top_options w-100 ${showOptions ? "show" : ""}`}>
                        <button className='close_options d-block d-sm-none' onClick={() => setShowOptions(false)}>
                            <img src={images?.close} alt="" width={15} />
                        </button>
                        <Search placeholder='Search Mails' />

                        <div className='d-sm-none'>
                            <div className='auth_team_aside_settings'>
                                <NavLink to="/team?type=add_team" className='ath_aside_setting_btns mt-2' onClick={() => setShowOptions(false)}>
                                    <img src={images?.person_add} altr="" height={12} /> Add Team Members
                                </NavLink>
                                <NavLink to="/team/settings" className='ath_aside_setting_btns mt-2' onClick={() => setShowOptions(false)}>
                                    <img src={images?.settings} altr="" height={15} /> Manage Team
                                </NavLink>
                            </div>
                        </div>
                        <button className='auth_header_account ms-0 ms-sm-4' onClick={() => openAside("company", "Your Company")}>
                            <div className='auth_header_account_logo me-2 me-lg-0'>
                                <img src={images?.profile_logo} alt={""} width={30} />
                            </div>
                            <p className='d-lg-block d-sm-none'>John Associates & Co.</p>
                            <img src={images?.arrow_drop_down} alt="" width={8} className='me-2' />
                        </button>

                        <button className='auth_header_profile ms-0 ms-sm-4' onClick={() => openAside("profile", "Your Account")}>
                            <div className='auth_header_account_logo'>
                                <img src={images?.profile} alt={""} width={30} />
                            </div>
                            <img src={images?.arrow_drop_down} alt="" width={8} className='ms-3' />
                        </button>
                    </div>
                </div>
            </div>
            {showAside ?
                <ProfileAside close={closeAside} title={asideData?.title} showHeader={true}>
                    {asideData?.type == "profile" ?
                        <ProfileComp profile={profile} type="profile" close={closeAside} />
                        :
                        <CompanyProfileComp profile={profile} type="profile" close={closeAside} />
                    }
                </ProfileAside>
                : null
            }
        </div>
    )
}
