import React from 'react'

export default function LoadingPage({ image, title, count, children, showProgress }) {
    return (
        <div className='loginPageContentSection signupstep3Page text-center'>
            <div className="container">
                <div className="signupstep3Page__box align-items-center">

                    <div className='signupstep3Page__img'>
                        <img src={image} className='d-block ' />
                    </div>
                    <div className='signupstep3Page__bottom'>
                        {showProgress ?
                            <>
                                <div className='mb-3 font16 med'>{title}</div>
                                <div className='progressbar'>
                                    <div className='progressbar__inner primary-bg' style={{ width: count + "%" }}></div>
                                </div>
                            </>
                            :
                            children
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
