import React from 'react'
import images from '../images'

export default function FiltersMail(props) {
    const { selected, filter, setFilter, onclick } = props
    return (
        <div className='auth_team_mail_filters_header'>
            <p>Filters</p>
            <div className='auth_team_mail_filters'>
                <div className='select_outer select_btn'>
                    <button className='common_btn text-dark' onClick={() => onclick("from")}>
                        {filter.from ?
                            `From - ${filter.from}` :
                            "From"
                        }
                    </button>
                    {/* <select>
                        <option selected value="" disabled>From</option>
                        {selected?.data?.mails.map((opt, ind) => {
                            return (
                                <option key={ind} value={filter.from}>{opt?.sender?.email}</option>
                            )
                        })}
                    </select> */}
                </div>
                <div className='select_outer select_btn ms-2'>
                    <button className='common_btn text-dark' onClick={() => onclick("to")}>
                        {filter.to ?
                            `To - ${filter.to}` :
                            "To"
                        }
                    </button>
                </div>
                <div className="select_outer select_btn datePicker ms-2">
                    <button className='common_btn text-dark' onClick={() => onclick("date")}>
                        {filter.date ?
                            filter.date :
                            "Date Range"
                        }
                    </button>
                    {/* <DatePicker
                        selected={filter.date}
                        className={"datepicker"}
                        onChange={(x) => setFilter({ ...filter, 'date': x })}
                        placeholderText="DD/MM/YYYY"
                        dateFormat="dd/MM/yyyy"
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    /> */}
                </div>
                <button className='ms-3' onClick={() => setFilter({ ...filter, show: false })}>
                    <img src={images.close} alt="" />
                </button>
            </div>
        </div>
    )
}
