import React from 'react'
import Container from '../Container'

export default function Team() {
  let menu = [
      { name: "Mails", link: "/team/mails" },
      { name: "Attachments", link: "/team/attachments" },
      { name: "Files", link: "/team/files" },
  ]
  return (
      <Container title={"My Team"} type="team" menu={menu} />
  )
}
