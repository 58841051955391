export default {
    logo: require("../../assets/images/logo.svg").default,
    team: require("../../assets/images/team-icon.svg").default,
    report: require("../../assets/images/report-icon.svg").default,
    contacts: require("../../assets/images/contacts-icon.svg").default,
    analytic: require("../../assets/images/analytic-icon.svg").default,
    arrow_drop_down: require("../../assets/images/arrow_drop_down.svg").default,
    person_add: require("../../assets/images/person_add.svg").default,
    settings: require("../../assets/images/settings.svg").default,
    sort: require("../../assets/images/sort.svg").default,
    check_email: require("../../assets/images/check_email.svg").default,
    open_in_new: require("../../assets/images/open_in_new.svg").default,
    download: require("../../assets/images/download.svg").default,
    download2: require("../../assets/images/download2.svg").default,
    download3: require("../../assets/images/download3.svg").default,
    arrow_back: require("../../assets/images/arrow_back.svg").default,
    close: require("../../assets/images/close.svg").default,
    filter_alt: require("../../assets/images/filter_alt.svg").default,
    devices: require("../../assets/images/devices.svg").default,
    logout: require("../../assets/images/logout.svg").default,
    beenhere: require("../../assets/images/beenhere.svg").default,
    encrypted: require("../../assets/images/encrypted.svg").default,
    mail: require("../../assets/images/mail.svg").default,
    navigate_next: require("../../assets/images/navigate_next.svg").default,
    help: require("../../assets/images/help.svg").default,
    delete: require("../../assets/images/delete.svg").default,
    delete_light: require("../../assets/images/delete-light.svg").default,
    refresh: require("../../assets/images/refresh.svg").default,
    sort_by_alpha: require("../../assets/images/sort_by_alpha.svg").default,
    sort_filter_list: require("../../assets/images/sort_filter_list.svg").default,
    grid_view: require("../../assets/images/grid_view.svg").default,
    view_list: require("../../assets/images/view_list.svg").default,
    notes: require("../../assets/images/note.svg").default,
    folder: require("../../assets/images/folder.svg").default,
    folder_open: require("../../assets/images/folder_open.svg").default,
    empty_contacts: require("../../assets/images/empty_contacts.svg").default,
    info: require("../../assets/images/info.svg").default,
    format_quote: require("../../assets/images/format_quote.svg").default,
    sentiment: require("../../assets/images/sentiment.svg").default,
    schedule: require("../../assets/images/schedule.svg").default,
    dangerous: require("../../assets/images/dangerous.svg").default,
    person_off: require("../../assets/images/person_off.svg").default,
    signal_cellular_alt: require("../../assets/images/signal_cellular_alt.svg").default,
    help_blue: require("../../assets/images/help_blue.svg").default,
    thumb_up: require("../../assets/images/thumb_up.svg").default,
    stacked_email: require("../../assets/images/stacked_email.svg").default,
    clock_loader_80: require("../../assets/images/clock_loader_80.svg").default,
    cloud_circle: require("../../assets/images/cloud_circle.svg").default,
    toggle: require("../../assets/images/toggle.svg").default,
    dots: require("../../assets/images/dots.svg").default,
    loader: require("../../assets/images/loader.png"),
    profile_logo: require("../../assets/images/profile-logo.png"),
    profile: require("../../assets/images/profile.png"),
}