import React, { useEffect, useState } from 'react'
import PlainHeader from '../PlainHeader/PlainHeader'
import Footer from '../Footer/Footer'
import images from '../images'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../LoadingPage/LoadingPage';


export default function SignupStep3() {
    let navigate = useNavigate();
    const [count, setCount] = useState(0)
    const [showAlert, setshowAlert] = useState(true)

    const MINUTE_MS = 100;

    const login = () => {
        localStorage["AUTH"] = true
        window.open("/team", "_self")
    }
    useEffect(() => {
        const interval = setInterval(() => {

            if (count >= 100) {
                clearInterval(interval)
                submitCompanyName()
                onShowAlert()
            } else {
                setCount(pc => pc + 2)
            }


        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [count])

    const onShowAlert = () => {
        setshowAlert(false)
        setTimeout(() => {
            login()
        }, 3000);
    }

    const submitCompanyName = (e) => {
        // navigate("/Signup/step-4")
    }
    return (
        <>
            <PlainHeader />
            <LoadingPage image={images.loadingGiff} title="Importing" count={count} showProgress={showAlert}>
                {!showAlert ?
                    <div>
                        <div className='common_btn rounded ps-2 pe-3 noHover'>
                            <img src={images?.check_circle} alt="" className='me-2' />
                            Teams imported Successfully
                        </div>
                    </div>
                    : null
                }
            </LoadingPage>
            <Footer />
        </ >
    )
}
