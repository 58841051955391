import React, { useEffect, useState } from 'react'
import images from '../images'
import { useNavigate } from 'react-router-dom'
import _ from "underscore";
import RemoveTeamMember from '../Team/RemoveTeamMember';
import { CustomModal } from '../Common/ManageCompany';
import { useSnackbar } from 'notistack';

const memberTable = [
    { id: 1, "name": "Aditya Dutta", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#007BFF", dot: 2 },
    { id: 2, "name": "Anurag Upadhayay", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#039000", dot: 2 },
    { id: 3, "name": "Ashwini Bhadane", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#F15A29", dot: 0 },
    { id: 4, "name": "Bhuvanesvari", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#0D0D82", dot: 1 },
    { id: 5, "name": "Girish Narsinghani", "position": "Chartered Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#039000", dot: 2 },
    { id: 6, "name": "Kajal Tejwani", "position": "Chartered Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#0D0D82", dot: 1 },
    { id: 7, "name": "Kashmira Paghare", "position": "Chartered Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#7252B7", dot: 0 },
    { id: 8, "name": "Reema Ahuja", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#FFA121", dot: 2 },
]

const strikeList = [
    { name: "1 Strike", value: "1" },
    { name: "2 Strike", value: "2" },
    { name: "3 Strike", value: "3" },
    { name: "Black list", value: "black list" },
]

export default function MemberStrike() {
    const navigate = useNavigate()
    const [active, setActive] = useState("1")
    const [members, setmembers] = useState(memberTable)
    const [children, setChildren] = useState({})
    const [modal, setModal] = useState({
        size: "md",
        show: false
    })
    const handleClose = () => {
        setModal({ ...modal, show: false })
        let arr = []
        members.map((opt)=>{
            arr.push({...opt, checked: false})
        })
        setmembers(arr)
    }
    const { enqueueSnackbar } = useSnackbar();
    const handleSubmit = () => {
        handleClose()
        enqueueSnackbar(`Resolved ${members.filter((x)=>x.checked).length} Members Strike`, {
            variant: 'success'
        })
    }

    const handleCheckAll = (e) => {
        let arr = []
        members.map((opt) => {
            opt.checked = e.target.checked
            arr.push(opt)
        })
        setmembers(arr)
    }
    const handleCheck = (e, data) => {
        let arr = []
        members.map((opt) => {
            if (opt.id == data.id) {
                opt.checked = e.target.checked
                arr.push(opt)
            } else {
                arr.push(opt)
            }
        })
        setmembers(arr)
    }
    
    const showRemoveMember = () => {
        setModal({ ...modal, show: true })
        setChildren(
            <RemoveTeamMember data={members.filter((x)=>x.checked)} handleClose={handleClose} handleSubmit={handleSubmit} title={`Are you sure you want to resolve their ${active} Strikes?`} type={"resolve"} children={<p className='text-danger'>5 Grammar , 1 behavior</p>} />
        )
    }

    return (
        <div className='device_logged_outer card border-0 p-3 custom_card'>
            <div className='d-flex justify-content-between align-items-center'>
                <button onClick={() => navigate(-1)} className='font16 med text-start primary-text sbold' >
                    <img className='me-2' src={images?.arrow_back} alt='' width={15} /> Members with Strike
                </button>
                <div className='auth_header_nav mx-4'>
                    {strikeList.map((nav) => {
                        return (
                            <button key={nav.name} className={nav.value == active ? "nav_link active" : "nav_link"} onClick={() => setActive(nav.value)}>
                                {nav?.name}
                            </button>
                        )
                    })}
                </div>
            </div>
            <hr />
            <div className='manage_company_outer mt-2'>
                <div className=" w-100 d-flex justify-content-between align-items-center">
                    <label className='custom_checkbox font12 d-flex'>
                        <span className='check_box me-2 ms-2'>
                            <input type='checkbox' onChange={handleCheckAll} checked={members.filter((x)=>x.checked).length == members.length} value={"all"} />
                            <span></span>
                        </span>
                        <p>Select all
                            <span className='mx-2 font20' style={{ lineHeight: 0, verticalAlign: "super", opacity: 0.5 }}>.</span>
                            {members.length || 0} Team Members
                        </p>
                    </label>
                    <div>
                        <button style={{ opacity: members.filter((x)=>x.checked).length > 0 ? 1 : 0, pointerEvents: members.filter((x)=>x.checked).length > 0 ? "all" : "none" }} className='common_btn common_btn_filled border-0 rounded' onClick={showRemoveMember}>
                            Resolve
                        </button>
                    </div>
                </div>
                <div className='member_table1 mt-3'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <td className='font12 text-light py-3'>Team Members</td>
                                <td className='font12 text-light py-3'>Grammer</td>
                                <td className='font12 text-light py-3'>Behaviour</td>
                                <td className='font12 text-light py-3'>Reply time</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <label className='custom_checkbox'>
                                                    <span className='check_box me-4 ms-2'>
                                                        <input type='checkbox' value={data.id} onChange={(e) => handleCheck(e, data)} checked={data?.checked} />
                                                        <span></span>
                                                    </span>
                                                </label>
                                                <div className='auth_team_badge' style={{ backgroundColor: data?.color }}>
                                                    {data.name.split(" ")[0].charAt(0).toUpperCase()}{data.name.split(" ")[1] ? data.name.split(" ")[1].charAt(0).toUpperCase() : data.name.split(" ")[0].charAt(1).toUpperCase()}
                                                </div>
                                                <div className='w-100'>
                                                    <p className='font12 text-dark mb-0'>{data.name}</p>
                                                    <p className='font12'>{data.position}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{data?.grammer}Mails</td>
                                        <td className='text-danger'>{data?.behaviour}Mails</td>
                                        <td>{data?.time} Day</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <CustomModal modal={modal} handleClose={handleClose} children={children} />
        </div>
    )
}
