import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { isLogIn } from './App';

export const PrivateRoute = () => {
    return isLogIn().isToken ? <Outlet /> : <Navigate to="/login" />;
}
export const PublicRoute = () => {
    return isLogIn().isToken ? <Navigate to="/team" /> : <Outlet />;
}
