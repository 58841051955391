import React from 'react'
import Container from '../Container'

export default function Contacts() {
  let menu = [
      { name: "Mails", link: "/contacts/mails" },
      { name: "Attachments", link: "/contacts/attachments" },
      { name: "Files", link: "/contacts/files" },
  ]
  return (
      <Container title={"Contacts"} type="contacts" menu={menu} />
  )
}
