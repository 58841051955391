import React, { useState } from 'react'
import PlainHeader from '../PlainHeader/PlainHeader'
import Footer from '../Footer/Footer'
import images from '../images'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

export default function Otp() {
    let navigate = useNavigate();
    const [error, seterror] = useState(false)
    const [value, setvalue] = useState({
        otp: ""
    })
    const login = () => {
        localStorage["AUTH"] = true
        window.open("/team", "_self")
    }
    const handleChange = (e) => {
        setvalue({ [e.target.name]: e.target.value })
    }
    const submitEmail = (e) => {
        
        if (value.otp === "") {
            seterror(true)
        } else {
            seterror(false)
            login()
        }

    }

    return (
        <>
            <PlainHeader />


            <div className='loginPageContentSection loginpage  pt-5 loginGwrkSpc'>
                <div className='loginSignUpPopRight'>
                    <div className='loginSignUpPopRight__top'>
                        <h3 className='loginSignUpPopRight__title sbold'>Enter Verification Code</h3>
                        <p className='font12 light'>Enter your Verification code to Login in your Account </p>
                    </div>  
                    <Form.Group className="loginGwrkSpc__input" >
                        <Form.Control type="email" placeholder="Enter OTP" name='otp' value={value.otp} onChange={handleChange} />
                        {error && value.otp === "" ?
                            <Form.Control.Feedback type="invalid" className='d-block'>
                                Please enter otp.
                            </Form.Control.Feedback> : null}

                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <button className='common_btn primary-bg w-100 bold py-2 ' onClick={submitEmail}> Next</button>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <button className='font12 primary-text mb-0 w-100 bold py-2 '> Resend</button>
                    </Form.Group>





                </div>
            </div>
            <Footer />
        </>
    )
}
