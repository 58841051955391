import React from 'react'
import images from '../images'
export default function Footer() {
    return (
        <div className='loginFooter'>
            <div className="container d-flex justify-content-between">
                <div className='loginFooter__left'>
                    <img src={images.chitthify} />
                </div>
                <div className='loginFooter__right'>
                    <ul className='loginFooter__ul d-flex align-items-center'>
                        <li><a href='#'>Terms</a></li>
                        <li><a href='#'>Privacy</a></li>
                        <li>Powered by Woodapples</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
