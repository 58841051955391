import React, { useEffect, useState } from 'react'
import PlainHeader from '../PlainHeader/PlainHeader'
import Footer from '../Footer/Footer'
import images from '../images'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import LoadingPage from '../LoadingPage/LoadingPage';


export default function SignupStep3() {
    let navigate = useNavigate();
    const [count, setCount] = useState(0)

    const MINUTE_MS = 100;

    useEffect(() => {
        const interval = setInterval(() => {

            if (count >= 100) {
                clearInterval(interval)
                submitCompanyName()
                navigate("/Signup/step-4")
            } else {
                setCount(pc => pc + 2)
            }


        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [count])


    const submitCompanyName = (e) => {
        // navigate("/Signup/step-4")
    }
    return (
        <>
            <PlainHeader />
            <LoadingPage image={images.loadingGiff} title="Importing Team Members" count={count} showProgress />
            <Footer />
        </ >
    )
}
