import React, { useRef, useState } from 'react'
import Alerts from '../Common/Alerts'
import images from '../images'
import "./Reports.css";
import { Search } from '../Common/Header';
import Dropdown from '../Common/Dropdown';
import { NavLink } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  Filler,
  ScriptableContext,
  ArcElement
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { emailData } from '../Container';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  LineElement,
  Tooltip,
  Legend,
  PointElement,
  Filler,
  ArcElement
);

const indexing = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Yearly", value: "Yearly" },
]
const filters = [
  { label: "Lowest Grammatical Mistake", value: "Lowest Grammatical Mistake", icon: images?.signal_cellular_alt },
  { label: "Highest Grammatical Mistake", value: "Highest Grammatical Mistake", icon: images?.signal_cellular_alt },
  { label: "Lowest Behavior Mistake", value: "Lowest Behavior Mistake", icon: images?.signal_cellular_alt },
  { label: "Highest Behavior Mistake", value: "Highest Behavior Mistake", icon: images?.signal_cellular_alt },
  { label: "Lowest Reply time", value: "Lowest Reply time", icon: images?.signal_cellular_alt },
  { label: "Highest Reply time", value: "Highest Reply time", icon: images?.signal_cellular_alt },
]

export const memberTable = [
  { id: 1, "name": "Aditya Dutta", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#007BFF", dot: 2, "data": emailData, size: 332, unit: "GB", "phone": "+91 9876543210", "email": "aditya.dutta@gmail.com", lastLogin: "12:55PM, 09 Oct, 2023", },
  { id: 2, "name": "Anurag Upadhayay", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#039000", dot: 2, "data": emailData, size: 332, unit: "GB", "phone": "+91 9876543210", "email": "anurag.upadhayay@gmail.com", lastLogin: "12:55PM, 09 Oct, 2023", },
  { id: 3, "name": "Ashwini Bhadane", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#F15A29", dot: 0, "data": emailData, size: 620, unit: "GB", "phone": "+91 9876543210", "email": "ashwini.bhadane@gmail.com", lastLogin: "12:55PM, 09 Oct, 2023", },
  { id: 4, "name": "Bhuvanesvari", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#0D0D82", dot: 1, "data": emailData, size: 332, unit: "GB", "phone": "+91 9876543210", "email": "bhuvaneswari@gmail.com", lastLogin: "12:55PM, 09 Oct, 2023", },
  { id: 5, "name": "Girish Narsinghani", "position": "Chartered Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#039000", dot: 2, "data": emailData, size: 332, unit: "GB", "phone": "+91 9876543210", "email": "girish.narsinghani@gmail.com", lastLogin: "12:55PM, 09 Oct, 2023", },
  { id: 6, "name": "Kajal Tejwani", "position": "Chartered Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#0D0D82", dot: 1, "data": emailData, size: 332, unit: "GB", "phone": "+91 9876543210", "email": "kajal.tejwani@gmail.com", lastLogin: "12:55PM, 09 Oct, 2023", },
  { id: 7, "name": "Kashmira Paghare", "position": "Chartered Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#7252B7", dot: 0, "data": emailData, size: 332, unit: "GB", "phone": "+91 9876543210", "email": "kashmira.paghare@gmail.com", lastLogin: "12:55PM, 09 Oct, 2023", },
  { id: 8, "name": "Reema Ahuja", "position": "Accountant", grammer: "22", behaviour: "01", time: "01", "color": "#FFA121", dot: 2, "data": emailData, size: 332, unit: "GB", "phone": "+91 9876543210", "email": "reema.ahuja@gmail.com", lastLogin: "12:55PM, 09 Oct, 2023", },
]
export default function Reports() {
  const topRef = useRef()
  const teamMembers = [
    { value: "45", label: "Grammatical Mistakes", icon: images?.format_quote },
    { value: "05", label: "Behavior Mistake", icon: images?.sentiment },
    { value: "1.5d", label: "Avg Reply time", icon: images?.schedule },
  ]

  const chartData = (type) => {
    return {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "",
          data: [10, 20, 30, 40, 50, 30, 10, 20, 5, 30, 55, 20],
          fill: true,
          backgroundColor: type == "1" ? "#F15A29" : type == "2" ? "#FFA121" : "#D9D9D9",
          borderColor: type == "1" ? "#F15A29" : type == "2" ? "#FFA121" : "#D9D9D9",
          borderRadius: window.innerWidth > 1199 ? "10" : "3",
          barThickness: window.innerWidth > 1199 ? 50 : 20,
        }
      ]
    }
  }
  const chartOptions = {
    indexAxis: 'x',
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#898989",
          font: {
            size: 12,
            family: "Lexend', sans-serif"
          }
        },
        grid: {
          display: false

        }
      },
      y: {
        ticks: {
          display: false
        },
        grid: {
          tickColor: "rgba(0,0,0,0)"
        },
        title: {
          display: false
        }
      }
    },
  }

  const [showAlert, setShowAlert] = useState(true)
  const [showDD, setShowDD] = useState("")
  const closeAlert = () => {
    setShowAlert(false)
  }

  const optionClick = (opt) => {
    setShowDD("")
  }
  console.log("topRef: ", topRef)

  return (
    <div className='auth_container_fluid'>
      <div className='header_container pb-md-0 pb-5'>
        <div className='row gy-3'>
          <div className='col-xxl-4 col-lg-6'>
            <div className='auth_team_setting_aside card custom_card p-3 h-100'>
              <div ref={topRef}>
                <h5 className='font16 primary-text'>Your Team Insights</h5>
                <hr />
                <Alerts variant='warning' dismissible showAlert={showAlert} closeAlert={closeAlert} >
                  <div className='d-flex justify-content-between align-items-center w-100'>
                    <p>More than 40 Team Members needs to revise their mail</p>
                    <NavLink to="/member-strikes" className='common_btn sm warning rounded mt-2 mt-sm-0'>View them</NavLink>
                  </div>
                </Alerts>
                <div className='my-3'>
                  <h5 className='font16 med'>No. of Team members</h5>
                  <div className='team_members_report mt-4 row'>
                    {teamMembers.map((opt, i) => {
                      return (
                        <div className='col-4 mb-ms-0 mb-3 team_members_report_analytic' key={i}>
                          <h2>{opt.value}</h2>
                          <div className='d-flex'>
                            <img src={opt.icon} alt="" width={20} className='me-2' />
                            <p style={{ flex: "0 0 100%" }}>{opt.label}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='auth_team_left_header'>
                  <h5 className='font16 sbold mb-0'>Team Members Insights</h5>
                  <div className='d-flex w-100 align-items-center mt-sm-0 mt-2'>
                    <div className='mx-3 w-100 ms-0 ms-sm-3'>
                      <Search placeholder={"Search Team"} />
                    </div>
                    <span className='position-relative'>
                      <button onClick={() => setShowDD("sort")}>
                        <img src={images?.sort} width={16} alt="" />
                      </button>
                      {showDD == "sort" ?
                        <Dropdown
                          close={() => setShowDD("")}
                        >
                          {filters.map((opt, i) => {
                            return (
                              <button key={i} onClick={() => optionClick(opt)} className='font10'>
                                {opt?.icon ?
                                  <span className='list_icon'>
                                    <img src={opt?.icon} alt="" className='me-2' />
                                  </span>
                                  : null
                                }
                                {opt?.label}
                              </button>
                            )
                          })}
                        </Dropdown>
                        : null
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className='member_table_outer'>
                <div className='member_table mt-3' style={{ maxHeight: topRef.current ? `calc(100vh - ${topRef.current.clientHeight + 70}px)` : 'calc(100vh - 470px)' }}>
                  <table className='table'>
                    <thead>
                      <tr>
                        <td className='font12 text-light py-3'>Team Members</td>
                        <td className='font12 text-light py-3'>Grammer</td>
                        <td className='font12 text-light py-3'>Behaviour</td>
                        <td className='font12 text-light py-3'>Reply time</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {memberTable.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <NavLink to={`/reports/member/${data.id}`} className='d-flex'>
                                <div className='auth_team_badge' style={{ backgroundColor: data?.color }}>
                                  {data.name.split(" ")[0].charAt(0).toUpperCase()}{data.name.split(" ")[1] ? data.name.split(" ")[1].charAt(0).toUpperCase() : data.name.split(" ")[0].charAt(1).toUpperCase()}
                                </div>
                                <div className='w-100'>
                                  <p className='font12 text-dark mb-0'>{data.name}</p>
                                  <p className='font12'>{data.position}</p>
                                </div>
                              </NavLink>
                            </td>
                            <td>{data?.grammer}Mails</td>
                            <td className='text-danger'>{data?.behaviour}Mails</td>
                            <td>{data?.time} Day</td>
                            <td>
                              <div className='d-flex'>
                                {[1, 2, 3].map((num) => {
                                  return (
                                    <img src={images.dangerous} alt="x" key={num} className={`ms-1 ${num > data.dot ? "disabled" : ""}`} />
                                  )
                                })}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
          <div className='col-xxl-8 col-lg-6'>
            <div className='auth_team_setting_aside card custom_card p-3'>
              <div className='team_indexing_outer'>
                <div className='team_indexing'>
                  <div className='select_outer select_btn border-0 position-relative'>
                    <button className='ps-0 common_btn border-0 sbold font14 mb-2 mb-sm-0' onClick={() => setShowDD("index")}>Team Monthly Indexing</button>
                    {showDD == "index" ?
                      <Dropdown
                        close={() => setShowDD("")}
                      >
                        {indexing.map((opt, i) => {
                          return (
                            <button key={i} onClick={() => optionClick(opt)} className='font10'>
                              {opt?.icon ?
                                <span className='list_icon'>
                                  <img src={opt?.icon} alt="" className='me-2' />
                                </span>
                                : null
                              }
                              {opt?.label}
                            </button>
                          )
                        })}
                      </Dropdown>
                      : null
                    }
                  </div>
                  <NavLink to="/member-strikes" className={"common_btn med"}>
                    <img src={images?.person_off} alt="" className='me-2' />
                    Members with Strike
                  </NavLink>
                </div>
                <hr />

                <div className='mb-4'>
                  <h5 className='mb-3 font16 text-dark'>Grammatical Mistakes</h5>
                  <Bar options={chartOptions} data={chartData("1")} height="70" />
                </div>
                <div className='mb-4'>
                  <h5 className='mb-3 font16 text-dark'>Behavior Mistakes</h5>
                  <Bar options={chartOptions} data={chartData("2")} height="70" />
                </div>
                <div className='mb-4'>
                  <h5 className='mb-3 font16 text-dark'>Reply time</h5>
                  <Bar options={chartOptions} data={chartData("3")} height="70" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
