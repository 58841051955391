import React, { useState } from 'react'
import { Search } from '../Common/Header'
import images from '../images'

export default function AddTeamMember(props) {
  const [teamData, setTeamData] = useState([
    {
      id: 100,
      "name": "Aditya Dutta",
      "position": "Accountant",
      "size": "332.5GB",
      "phone": "+91 9876543210",
      "email": "aditya.dutta@gmail.com",
      "color": "#007BFF",
    },
    {
      id: 101,
      "name": "Anurag Upadhayay",
      "position": "Accountant",
      "size": "120GB",
      "phone": "+91 9876543210",
      "email": "anurag.upadhayay@gmail.com",
      "color": "#039000",
    },
    {
      id: 102,
      "name": "Ashwini Bhadane",
      "position": "Accountant",
      "size": "220GB",
      "phone": "+91 9876543210",
      "email": "ashwini.bhadane@gmail.com",
      "color": "#F15A29",
    }
  ])
  return (
    <div className=''>
      <h5 className='font16 primary-text mb-4'><img src={images?.person_add} altr="" height={12} /> Add Team Member</h5>
      <Search placeholder={"Type Team Email Id"} style={{ borderRadius: "10px" }} />
      <div className='add_team_list'>
        {teamData.map((data, i) => {
          return (
            <label className='custom_checkbox w-100' key={i}>
              <div className='auth_team_group py-2'>
                <div className="auth_team_group_item">
                  <span className='check_box me-4 ms-2'>
                    <input type='checkbox' />
                    <span></span>
                  </span>
                  <div className='auth_team_badge' style={{ backgroundColor: data?.color }}>
                    {data.name.split(" ")[0].charAt(0).toUpperCase()}{data.name.split(" ")[1] ? data.name.split(" ")[1].charAt(0).toUpperCase() : data.name.split(" ")[0].charAt(1).toUpperCase()}
                  </div>
                  <div className='w-100'>
                    <div className='row align-items-end'>
                      <div className='col-md-6'>
                        <p className='font12 text-dark mb-0'>{data.name}</p>
                        <p className='font12'>{data.position}</p>
                      </div>
                      <div className='col-md-6 text-start text-sm-end'>
                        <p className='font10 mb-0'>{data.size}</p>
                        <p className='font10'>{data.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </label>
          )
        })}
      </div>
      <div className='text-end'>
        <span>
          <button className='common_btn primary-bg' onClick={props.handleClose}>Add</button>
          <button className='mx-3' onClick={props.handleClose}><p>Cancel</p></button>
        </span>
      </div>
    </div>
  )
}
