import React from 'react'
import images from '../images'
import './Home.css'
import { useState } from 'react'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'
export default function Home() {
    const [showSignupPop, setshowSignupPop] = useState(false) 
    const [activetab, setactivetab] = useState("login") 
    const toggleSignupPop = (tab) => {
        setactivetab(tab)
        setshowSignupPop(!showSignupPop)
    }
    const popupType = (tab) => { 
        setactivetab(tab)   
    }


    return (
        <>
            <div className='loginHeader'>
                <div className="container d-flex justify-content-between">
                    <div className='loginHeader__left'>
                        <img src={images.logo} className='d-block' width={120} />
                    </div>
                    <div className='loginHeader__right d-flex align-items-center'>
                        <button className='font12 sbold primary-text mb-0 text-start' onClick={() => toggleSignupPop("login")}>Login</button>
                        <button className='common_btn primary-bg ' onClick={() => toggleSignupPop("signup")} >Get Started</button>
                    </div>
                </div>
            </div>
            <div className='loginPageContentSection loginpage'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className='loginPageContent__cntnt'>
                                <h1 className='loginPageContent__title'>Revolutionize Client Email Handling with MailInsure</h1>
                                <p className='font16'>Your email time machine. Effortlessly manage team emails, preserving client interactions for future success.</p>
                            </div>
                            <button className='common_btn primary-bg ' onClick={() => toggleSignupPop("signup")}>Get Started</button>
                        </div>
                        <div className="col-md-6">
                            <img src={images.homeBannerImg} className='d-block ' />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            <div className={showSignupPop ? 'loginSignUpPop active' : "loginSignUpPop "}>
                <div className='loginHeader'>
                    <div className="container d-flex justify-content-between">
                        <div className='loginHeader__left'>
                            <img src={images.logo} className='d-block' width={120} />
                        </div>
                        <div className='loginHeader__right d-flex align-items-center'>
                            <button className='font12 sbold primary-text mb-0 text-start' onClick={toggleSignupPop} ><img src={images.close1} alt="X" /> </button>
                        </div>
                    </div>
                </div>
                <div className='loginSignUpPop__center'>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className='loginSignUpPopLeft'>
                                    <div className='loginSignUpPopLeft__img'>
                                        <img src={images.signupPopImg} className='d-block img-fluid ' />
                                    </div>
                                    <div className='loginSignUpPopLeft__listBox'>
                                        <ul className='loginSignUpPopLeft__list d-flex list-style-none align-items-center justify-content-center'>
                                            <li className='d-flex align-items-center'>
                                                <span className='loginSignUpPopLeft__listIcon'><img className='d-block img-fluid' src={images.effortlessOrganization} alt="" /></span>
                                                <span className='loginSignUpPopLeft__listText font14 sbold '>Effortless Organization</span>
                                            </li>
                                            <li className='d-flex align-items-center'>
                                                <span className='loginSignUpPopLeft__listIcon'><img className='d-block img-fluid' src={images.intelligentSearch} alt="" /></span>
                                                <span className='loginSignUpPopLeft__listText font14 sbold '>Intelligent Search</span>
                                            </li>
                                            <li className='d-flex align-items-center'>
                                                <span className='loginSignUpPopLeft__listIcon'><img className='d-block img-fluid' src={images.collaborationMadeSimple} alt="" /></span>
                                                <span className='loginSignUpPopLeft__listText font14 sbold '>Collaboration Made Simple</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className='loginSignUpPopRight'>
                                    <div className='loginSignUpPopRight__top'>
                                        <h3 className='loginSignUpPopRight__title sbold'>
                                            {activetab === "signup" ? " Sign up Today" : "Login in your Account"}
                                        </h3>
                                        <p className='font12 light'>Select your Email Workspace</p>
                                    </div>
                                    <div className='loginSignUpPopRight__buttons'>
                                    {/* <Link to="/login" */}
                                    {activetab === "signup" ? 
                                        <Link to="/signup" className='loginSignUpPopRight__btn w-100'>
                                            <img src={images.googleWorkSpace} alt="" height="16" />
                                        </Link> :  <Link to="/login" className='loginSignUpPopRight__btn w-100'>
                                            <img src={images.googleWorkSpace} alt="" height="16" />
                                        </Link>
                                        }
                                        <button className='loginSignUpPopRight__btn w-100'>
                                            <img src={images.microsoft365} alt="" height="22" />
                                        </button>
                                    </div>
                                    {activetab === "signup" ?
                                        <div className="loginSignUpPopRight__already font12 med text-light"> Already have an account? <button className='font12 sbold primary-text mb-0 text-start' onClick={() => popupType("login")}> Login  </button></div> :
                                        <div className="loginSignUpPopRight__already font12 med text-light"> Don’t have an Account?  <button className='font12 sbold primary-text mb-0 text-start' onClick={() => popupType("signup")}> Sign up?  </button></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
