import React, { useState } from 'react'
import PlainHeader from '../PlainHeader/PlainHeader'
import Footer from '../Footer/Footer'
import images from '../images'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  let navigate = useNavigate();
  const [error, seterror] = useState(false)
  const [value, setvalue] = useState({
    email: ""
  })
  const [invalidEmail, setInvalidEmail] = useState(false)

  const handleChange = (e) => {
    setvalue({ [e.target.name]: e.target.value })
  }
  const submitEmail = (e) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (value.email === "") {
      seterror(true)
    } else if (value.email !== "" && !re.test(value.email)) {
      seterror(true)
      setInvalidEmail(true)
    } else {
      seterror(false)
      setInvalidEmail(false)
      navigate("/login/otp")


    }

  }

  return (
    <>
      <PlainHeader />


      <div className='loginPageContentSection loginpage  pt-5 loginGwrkSpc'>
        <div className='loginSignUpPopRight'>
          <div className='loginSignUpPopRight__top'>
            <h3 className='loginSignUpPopRight__title sbold'>Enter Email Address</h3>
            <p className='font12 light'>Enter your Registered Email Id to login </p>
          </div>

          <div className='loginGwrkSpc__backBtn'>
            <button onClick={() => navigate(-1)} className='LoginbackButton d-flex w-100 g-10 align-items-center '>
              <img src={images.arrowBack} alt="back" className='arrow' />
              <img src={images.googleWorkSpace} alt="" height="16" />
            </button>
          </div>


          <Form.Group className="loginGwrkSpc__input" >
            <Form.Control type="email" placeholder="Your Email Id" name='email' value={value.email} onChange={handleChange} />
            {error && value.email === "" ?
              <Form.Control.Feedback type="invalid" className='d-block'>
                Please enter email.
              </Form.Control.Feedback> : null}
            {error && invalidEmail ?
              <Form.Control.Feedback type="invalid" className='d-block'>
                Please enter valid email.
              </Form.Control.Feedback> : null}
          </Form.Group>
          <Form.Group className="mb-3" >
            <button className='common_btn primary-bg w-100 bold py-2 ' onClick={submitEmail}> Next</button>
          </Form.Group>





        </div>
      </div>
      <Footer />
    </>
  )
}
