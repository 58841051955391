import React, { useState } from 'react'
import PlainHeader from '../PlainHeader/PlainHeader'
import Footer from '../Footer/Footer'
import image from '../images'
import Form from 'react-bootstrap/Form';
import { NavLink, useNavigate } from 'react-router-dom';
import { Search } from '../../private/Common/Header';
import { team } from '../../private/Container';
import images from '../../private/images';



export default function SignupStep4() {
    let navigate = useNavigate();
    const [teamData, setTeamData] = useState(team)

    return (
        <>
            <PlainHeader />
            <div className='loginPageContentSection loginpage'>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-4">
                            <div className='mx-3 w-100'>
                                <Search placeholder={"Search Team"} />
                                <div className='auth_team_list auth_team_list_import'>
                                    {
                                        Object.keys(teamData).map((keys) => {
                                            return (
                                                <div key={keys}>
                                                    <p className='team_group font12'><span>{keys}</span></p>
                                                    <div className='auth_team_group'>
                                                        {teamData[keys].map((data, i) => {
                                                            return (
                                                                <div className="auth_team_group_item" key={i}>
                                                                    <div className='auth_team_badge' style={{ backgroundColor: data?.color }}>
                                                                        {data.name.split(" ")[0].charAt(0).toUpperCase()}{data.name.split(" ")[1] ? data.name.split(" ")[1].charAt(0).toUpperCase() : data.name.split(" ")[0].charAt(1).toUpperCase()}
                                                                    </div>
                                                                    <div className='w-100'>
                                                                        <div className='row align-items-end'>
                                                                            <div className='col-md-6'>
                                                                                <p className='font12 text-dark mb-0'>{data.name}</p>
                                                                                <p className='font10'>{data.email}</p>
                                                                            </div>
                                                                            <div className='col-md-6 text-end'>
                                                                                <img src={images?.close} alt="" width={15} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3 className='font24'>Importing your Team</h3>
                            <h5 className='font14 light'>Importing <span style={{ color: 'var(--primary-light)' }}>9 Team</span> members from workspace</h5>
                            <div className='my-4'>
                                <img src={image?.import_member} alt="" />
                            </div>
                            <NavLink to="/Signup/step-5" className='common_btn w-100 primary-bg lg'>Import</NavLink>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </ >
    )
}
