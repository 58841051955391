import React, { useEffect, useState } from 'react'
import MailDetail from '../Mails/MailDetail'
import { mailContent } from '../Container'
import MailTable from '../Mails/MailTable'
import _ from "underscore"
import { useNavigate, useParams } from 'react-router-dom'
import { memberTable } from '.'
import images from '../images'

export default function ReportMails() {
    const navigate = useNavigate()
    const param = useParams()
    const [selected, setSelected] = useState({})
    const [mailData, setMailData] = useState({})
    const handleMailClick = () => {

    }

    useEffect(() => {
        if (param.id) {
            let obj = memberTable.filter((x) => x.id == param.id)
            if (obj.length > 0) {
                setSelected(obj[0])
            }
        }
    }, [])
    console.log("report selected", selected)
    return (
        <div className='page_modal'>
            <div className='page_modal_header mb-3 py-3 bg-white'>
                <div className='container'>
                    {!_.isEmpty(selected) ?
                        <div className='d-flex justify-content-between'>
                            <button onClick={() => navigate(-1)} className='font16 text-start primary-text team_personal_info' >
                                <img className='me-3' src={images?.arrow_back} alt='' width={25} />
                                <div className='auth_team_badge sm' style={{ backgroundColor: selected?.color }}>
                                    {selected?.name.split(" ")[0].charAt(0).toUpperCase()}{selected?.name.split(" ")[1] ? selected?.name.split(" ")[1].charAt(0).toUpperCase() : selected?.name.split(" ")[0].charAt(1).toUpperCase()}
                                </div>
                                <div className='w-100'>
                                    <p className='font12 text-dark mb-0'>{selected?.name}</p>
                                    <p className='font12 mb-0'>{selected?.position}</p>
                                </div>
                            </button>
                            <div className='member_mail_details'>
                                <div>
                                    <p className='text-dark mb-1'>{selected?.grammer} Mails</p>
                                    <p className='mb-0'>Grammar</p>
                                </div>
                                <div className='ms-5'>
                                    <p className='text-dark mb-1 text_danger'>{selected?.behaviour} Mails</p>
                                    <p className='mb-0'>Behavior</p>
                                </div>
                                <div className='ms-5'>
                                    <p className='text-dark mb-1'>{selected?.time} Day</p>
                                    <p className='mb-0'>Avg Reply Time</p>
                                </div>
                                <div className='ms-5'>
                                    <div className='d-flex'>
                                        {[1, 2, 3].map((num) => {
                                            return (
                                                <img src={images.dangerous} alt="x" key={num} className={`ms-1 ${num > selected.dot ? "disabled" : ""}`} />
                                            )
                                        })}
                                    </div>
                                    <p className='mb-0'>{selected?.dot} Strikes</p>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
            <div className='page_modal_body'>
                <div className='container'>
                    <div className='card border-0 p-3 custom_card'>
                        {!_.isEmpty(mailData) ?
                            <MailDetail
                                mailData={mailData}
                                setMailData={setMailData}
                                selected={selected}
                                mailContent={mailContent}
                                openMail={true}
                            />
                            :
                            <MailTable
                                setMailData={setMailData}
                                mailContent={mailContent}
                                handleMailClick={handleMailClick}
                                selected={selected}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
