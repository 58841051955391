import React, { useRef, useState } from 'react'
import Alerts from '../Common/Alerts'
import images from '../images'
import "./Analytics.css";
import { ProfileAside, ProfileComp, Search } from '../Common/Header';
import Dropdown from '../Common/Dropdown';
import { NavLink } from 'react-router-dom';
import { emailData } from '../Container';
import { memberTable } from '../Reports';

const indexing = [
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
  { label: "Yearly", value: "Yearly" },
]
const filters = [
  { label: "Lowest Storage used", value: "Lowest Storage used", icon: images?.signal_cellular_alt },
  { label: "Highest Storage used", value: "Highest Storage used", icon: images?.signal_cellular_alt },
]

export default function Analytics() {
  const topRef = useRef()
  const teamMembers = [
    { value: "50", label: "Large Size mails", icon: images?.stacked_email, link: "/analytic/large-mails" },
    { value: "10", label: "Storage above 80%", icon: images?.clock_loader_80, link: "/analytic/low-storage" },
    { value: "220gb", label: "Your Storage", icon: images?.cloud_circle, link: "" },
  ]
  const [showAlert, setShowAlert] = useState(true)
  const [showDD, setShowDD] = useState("")
  const [showAside, setshowAside] = useState(false)
  const [selected, setselected] = useState({})

  const closeAlert = () => {
    setShowAlert(false)
    setselected("")
  }

  const handleClose = () => {
    setShowAlert(false)
    setselected("")
    setshowAside(false)
  }
  const optionClick = (opt) => {
    setShowDD("")
  }
  const handleAside = (data) => {
    setselected(data)
    setshowAside(true)
  }
  console.log("topRef: ", topRef)

  return (
    <div className='auth_container_fluid'>
      <div className='header_container'>
        <div className='row justify-content-center'>
          <div className='analytic_outer'>
            <div className='auth_team_setting_aside card custom_card p-3 h-100'>
              <div ref={topRef}>
                <h5 className='font16 primary-text'>Mail insights</h5>
                <hr />
                <Alerts variant='warning' dismissible showAlert={showAlert} closeAlert={closeAlert} >
                  <div className='d-flex justify-content-between align-items-center w-100'>
                    <p>More than 10 Team Members have used up 80% their Storage</p>
                    <NavLink to="/analytic/low-storage" className='common_btn sm warning rounded mt-2 mt-sm-0'>View them</NavLink>
                  </div>
                </Alerts>
                <div className='my-3'>
                  <h5 className='font16 med'>Mail insights</h5>
                  <div className='team_members_report mt-4 row'>
                    {teamMembers.map((opt, i) => {
                      return (
                        <NavLink to={opt?.link} className='col-4 mb-ms-0 mb-3 team_members_report_analytic' key={i}>
                          <h2>{opt.value}</h2>
                          <div className='d-flex'>
                            <img src={opt.icon} alt="" width={20} className='me-2' />
                            <p style={{ flex: "0 0 100%" }} className={opt.link ? "isLink" : ""}>
                              {opt.label}
                            </p>
                          </div>
                        </NavLink>
                      )
                    })}
                  </div>
                </div>
                <div className='auth_team_left_header'>
                  <h5 className='font16 sbold mb-0'>Team Members Storage</h5>
                  <div className='d-flex w-100 align-items-center mt-sm-0 mt-2'>
                    <div className='mx-3 w-100 ms-0 ms-sm-3'>
                      <Search placeholder={"Search Team"} />
                    </div>
                    <span className='position-relative'>
                      <button onClick={() => setShowDD("sort")}>
                        <img src={images?.sort} width={16} alt="" />
                      </button>
                      {showDD == "sort" ?
                        <Dropdown
                          close={() => setShowDD("")}
                        >
                          {filters.map((opt, i) => {
                            return (
                              <button key={i} onClick={() => optionClick(opt)} className='font10'>
                                {opt?.icon ?
                                  <span className='list_icon'>
                                    <img src={opt?.icon} alt="" className='me-2' />
                                  </span>
                                  : null
                                }
                                {opt?.label}
                              </button>
                            )
                          })}
                        </Dropdown>
                        : null
                      }
                    </span>
                  </div>
                </div>
              </div>
              <div className='member_table_outer'>
                <div className='member_table mt-3' style={{ maxHeight: topRef.current ? `calc(100vh - ${topRef.current.clientHeight + 70}px)` : 'calc(100vh - 470px)' }}>
                  <table className='table'>
                    <tbody>
                      {memberTable.map((data, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <button className='text-start d-flex' onClick={() => handleAside(data)}>
                                <div className='auth_team_badge' style={{ backgroundColor: data?.color }}>
                                  {data.name.split(" ")[0].charAt(0).toUpperCase()}{data.name.split(" ")[1] ? data.name.split(" ")[1].charAt(0).toUpperCase() : data.name.split(" ")[0].charAt(1).toUpperCase()}
                                </div>
                                <div className='w-100'>
                                  <p className='font12 text-dark mb-0'>{data.name}</p>
                                  <p className='font12'>{data.position}</p>
                                </div>
                              </button>
                            </td>
                            <td width={180}>
                              <div className='text-end' style={{ whiteSpace: "nowrap" }}>
                                <p className="mb-1 font10 warning_icon">
                                  {data?.size > 500 ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--warning)" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                      <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                                      <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                                    </svg>
                                    : null
                                  }
                                  {data?.size}{data?.unit || "gb"}/690.0GB</p>
                                <div className='storage_bar_outer' style={{ width: 180, flex: "0 0 180px" }}>
                                  <span style={{ width: `${parseFloat(data?.size / 690 * 100)}%`, backgroundColor: "#007BFF" }}></span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div >

      {showAside ?
        <ProfileAside close={handleClose} showHeader={false}>
          <ProfileComp profile={selected} type="insight" />
          <div className='recipent_search'>
            <hr />
            <button className='common_btn primary-bg w-100 rounded mb-3'>Open Mails</button>
            <button className='common_btn w-100 rounded'>Open Files</button>
          </div>
        </ProfileAside>
        : null
      }
    </div >
  )
}
