import React, { useState } from 'react'
import PlainHeader from '../PlainHeader/PlainHeader'
import Footer from '../Footer/Footer'
import images from '../images'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';



export default function SignupStep2() {
    let navigate = useNavigate();
    const [error, seterror] = useState(false)
  


   
    
    const submitCompanyName = (e) => { 
        navigate("/Signup/step-3") 


    }

    return (
        <>
            <PlainHeader />

            <div className='loginPageContentSection loginpage'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={images.signupPage1Img} className='d-block ' />
                        </div>
                        <div className="col-md-6">
                            <div className='signupcard'>

                                <div className='aside_profile_body mb-5'>
                                    <div className='aside_profile_img mb-4'>

                                        <img src={images.profile2} alt="" width={100} />


                                    </div>
                                    <h5 className='font16 med mb-1'> John Doe</h5>
                                    <p className='font12 med primary-text  mb-1'>Admin</p>
                                    <p className='font12 mb-1'>john.doe@gmail.com</p>
                                    <p className='font12'>+91 9876543210</p>
                                </div>



                                <Form.Group className="mb-3" >
                                    <button className='common_btn primary-bg w-100 bold py-2 ' onClick={submitCompanyName}> Next</button>
                                </Form.Group>





                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </ >
    )
}
