import React from 'react'
import images from '../images'
import { Link } from 'react-router-dom'

export default function PlainHeader() {
    return (
        <div className='loginHeader'>
            <div className="container d-flex justify-content-between">
                <div className='loginHeader__left'>
                    <Link to="/"><img src={images.logo} className='d-block' width={120} /></Link>
                </div>
                <div className='loginHeader__right d-flex align-items-center'> 
                </div>
            </div>
        </div>
    )
}
