import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'

export default function Alerts(props) {
    // const [show, setShow] = useState(props.showAlert || true)
    let icons = [
        {
            variant: "success", icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--warning)" class="bi bi-check-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
            </svg>, bg: "rgba(210,255,215,0.85)", color: "var(--warning)"
        },
        {
            variant: "danger", icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--warning)" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>, bg: "rgba(255,198,180,0.85)", color: "var(--warning)"
        },
        {
            variant: "warning", icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--warning)" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
            </svg>, bg: "rgba(255,246,243,0.85)", color: "var(--warning)"
        },
    ]
    return (
        <>
            {props.showAlert ?
                <Alert className='custom_alert font12' variant={props.variant} onClose={props.closeAlert} dismissible={props.dismissible} style={{ color: icons.filter((x) => x.variant == props.variant)[0]?.color, backgroundColor: icons.filter((x) => x.variant == props.variant)[0]?.bg }}>
                    {icons.filter((x) => x.variant == props.variant)[0]?.icon}
                    {props.children}
                </Alert>
                : null
            }
        </>
    )
}
