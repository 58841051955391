import React from 'react'
import images from '../images'

export default function MailDetail(props) {
    const {mailData, setMailData, selected, mailContent, openMail, setopenMail } = props
    return (
        <div className='auth_team_mail_read'>
            <div className='mail_read_header'>
                <button className='font12 med' onClick={() => setMailData({})}><img className='me-2' src={images?.arrow_back} alt='' /> {mailData?.mail?.subject}</button>
                <button className='font12 med'><img className='me-2' src={images?.download} alt='' /></button>
            </div>
            <div className='mail_read_body'>
                <div className='mail_sender_detail'>
                    <div className='team_personal_info mb-3'>
                        <div className='auth_team_badge sm' style={{ backgroundColor: mailData?.sender?.color }}>
                            {mailData?.sender?.name.split(" ")[0].charAt(0).toUpperCase()}{mailData?.sender?.name.split(" ")[1] ? mailData?.sender?.name.split(" ")[1].charAt(0).toUpperCase() : mailData?.sender?.name.split(" ")[0].charAt(1).toUpperCase()}
                        </div>
                        <div className='w-100'>
                            <p className='font12 text-dark mb-1 sbold'>{mailData?.sender?.name} <span className='text-light reg'>. {mailData?.sender?.email}</span>
                            </p>
                            <p className='font12'>{mailData?.mail?.date}</p>
                        </div>
                    </div>
                    <div className='font12 text-light'>
                        <p className='font12 mb-1'>TO: {selected?.email}</p>
                        CC: {mailContent?.cc.map((ccs) => {
                            return (
                                <span key={ccs} className='mb-1 font12 text-light'>{ccs}</span>
                            )
                        })}
                    </div>
                </div>
                <div className={`mail_sender_detail mail_read_body_content ${openMail ? "" : "less"}`}>
                    <div className='mail_read_body_text' dangerouslySetInnerHTML={{ __html: mailContent?.text }}></div>

                    {!openMail ?
                        <div className='mail_read_more_btn'>
                            <button className='common_btn rounded primary-text' onClick={() => setopenMail(true)}>
                                <img src={images?.open_in_new} alt="" className='me-2' />Open mail</button>
                        </div>
                        : null
                    }
                </div>

                {mailData?.mail?.attachments &&
                    <div className='mail_read_body_attachments'>
                    <h5 className='font12 mb-3 primary-text'>Attachments</h5>
                        {mailContent?.attachments.map((attach, ii) => {
                            return (
                                <button className='mail_read_attachment_btn' key={ii}>
                                    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.54688 10.1875H7.46875C7.60156 10.1875 7.71289 10.1428 7.80273 10.0534C7.89258 9.964 7.9375 9.85322 7.9375 9.72106C7.9375 9.58891 7.89258 9.47736 7.80273 9.38642C7.71289 9.29547 7.60156 9.25 7.46875 9.25H3.54688C3.41406 9.25 3.30273 9.2947 3.21289 9.38409C3.12305 9.4735 3.07812 9.58428 3.07812 9.71644C3.07812 9.84859 3.12305 9.96014 3.21289 10.0511C3.30273 10.142 3.41406 10.1875 3.54688 10.1875ZM3.54688 7.53125H7.45312C7.58594 7.53125 7.69727 7.48655 7.78711 7.39716C7.87695 7.30775 7.92188 7.19697 7.92188 7.06481C7.92188 6.93266 7.87695 6.82111 7.78711 6.73017C7.69727 6.63922 7.58594 6.59375 7.45312 6.59375H3.54688C3.41406 6.59375 3.30273 6.63845 3.21289 6.72784C3.12305 6.81725 3.07812 6.92803 3.07812 7.06019C3.07812 7.19234 3.12305 7.30389 3.21289 7.39483C3.30273 7.48578 3.41406 7.53125 3.54688 7.53125ZM2.07211 12.625C1.67273 12.625 1.33691 12.4889 1.06464 12.2166C0.79238 11.9443 0.65625 11.6085 0.65625 11.2091V1.79086C0.65625 1.39148 0.79238 1.05566 1.06464 0.783391C1.33691 0.511131 1.67273 0.375 2.07211 0.375H6.28366C6.47837 0.375 6.66397 0.413114 6.84044 0.489344C7.01691 0.565562 7.16985 0.668875 7.29928 0.799281L9.91947 3.40384C10.0499 3.53327 10.1532 3.68622 10.2294 3.86269C10.3056 4.03916 10.3438 4.22475 10.3438 4.41947V11.2091C10.3438 11.6085 10.2076 11.9443 9.93536 12.2166C9.66309 12.4889 9.32727 12.625 8.92789 12.625H2.07211ZM6.375 3.64062C6.375 3.83437 6.44375 4 6.58125 4.1375C6.71875 4.275 6.88438 4.34375 7.07813 4.34375H9.3125L6.375 1.40625V3.64062Z" fill={`${attach?.type == "xlsx" ? "#039100" : "#007BFF"}`} />
                                    </svg>
                                    <span className='ms-2 me-4 reg'>{attach?.name}</span>
                                    <img src={images?.download} alt='' style={{ opacity: 0.3 }} />
                                </button>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    )
}
