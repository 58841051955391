export default {
    logo: require("../../assets/images/logo.svg").default,
    homeBannerImg: require("../../assets/images/homeBannerImg.svg").default,
    chitthify: require("../../assets/images/chitthify.svg").default,
    signupPopImg: require("../../assets/images/signupPopImg.svg").default,

    collaborationMadeSimple: require("../../assets/images/collaborationMadeSimple.svg").default,
    effortlessOrganization: require("../../assets/images/effortlessOrganization.svg").default,
    intelligentSearch: require("../../assets/images/intelligentSearch.svg").default,
    googleWorkSpace: require("../../assets/images/googleWorkSpace.png"),
    microsoft365: require("../../assets/images/microsoft365.png"),
    
    close1: require("../../assets/images/close.svg").default,
    arrowBack: require("../../assets/images/arrow_back.svg").default,
    signupPage1Img: require("../../assets/images/signupPage1Img.svg").default,
    profile2: require("../../assets/images/profile.png"), 
    loadingGiff: require("../../assets/images/loadingGiff.svg").default,
    import_member: require("../../assets/images/import_member.svg").default,
    check_circle: require("../../assets/images/check_circle.svg").default,
    
}