import React, { Children } from 'react'

export default function Dropdown(props) {
    return (
        <div>
            <div className='custom_dropdown_backdrop' onClick={props.close}></div>
            <div className='custom_dropdown_list_outer left' onClick={props.close}>
                <div className='custom_dropdown_list'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
