import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import "./components/private/custom.css";
import Team from './components/private/Team';
import { PrivateRoute, PublicRoute } from './PrivateAuth';
import { Header } from './components/private/Common/Header';
import DeviceLogged from './components/private/Common/DeviceLogged';
import ManageCompany from './components/private/Common/ManageCompany';
import Home from './components/public/Home/Home';
import Settings from './components/private/Team/Settings';
import Login from './components/public/Login/Login';
import Otp from './components/public/Login/Otp';
import Contacts from './components/private/Contacts';
import Reports from './components/private/Reports';
import Signup from './components/public/Signup/Signup';
import SignupStep2 from'./components/public/Signup/SignupStep2'
import SignupStep3 from './components/public/Signup/SignupStep3';
import SignupStep4 from './components/public/Signup/SignupStep4';
import SignupStep5 from './components/public/Signup/SignupStep5';

import MemberStrike from './components/private/Reports/MemberStrike';
import ReportMails from './components/private/Reports/ReportMails';
import Analytics from './components/private/Analytics';
import LowStorage from './components/private/Analytics/LowStorage';
import LargeMails from './components/private/Analytics/LargeMails';

export const isLogIn = () => {
  if (localStorage.getItem("AUTH")) {
    return { isToken: true };
  } else {
    return { isToken: false };
  }
};

export const logout = () => {
  localStorage.clear()
  window.open("/", "_self")
}

function App() {
  return (
    <Router>
      {isLogIn().isToken ?
        <main className='auth_outer'>
          <Header />
          <Routes>
            <Route exact path='/' element={<PrivateRoute />}>
              <Route path={"/device-loggedin"} element={<DeviceLogged />} />
              <Route path={"/manage-company"} element={<ManageCompany />} />
              <Route path={"/"} element={<Team />} />
              <Route path={"/team"} element={<Team />} />
              <Route path={"/team/:id"} element={<Team />} />
              <Route path={"/contacts"} element={<Contacts />} />
              <Route path={"/contacts/:id"} element={<Contacts />} />
              <Route path={"/team/settings"} element={<Settings />} />
              <Route path={"/reports"} element={<Reports />} />
              <Route path={"/reports/member/:id"} element={<ReportMails />} />
              <Route path={"/member-strikes"} element={<MemberStrike />} />
              <Route path={"/analytic"} element={<Analytics />} />
              <Route path={"/analytic/low-storage"} element={<LowStorage />} />
              <Route path={"/analytic/large-mails"} element={<LargeMails />} />
            </Route>
          </Routes>
        </main>
        :
        <Routes>
          <Route exact path='/' element={<PublicRoute />}>
            <Route path={"/"} element={<Home />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"/login/otp"} element={<Otp />} />
            <Route path={"/Signup"} element={<Signup />} />
            <Route path={"/Signup/step-2"} element={<SignupStep2 />} />
            <Route path={"/Signup/step-3"} element={<SignupStep3 />} />
            <Route path={"/Signup/step-4"} element={<SignupStep4 />} />
            <Route path={"/Signup/step-5"} element={<SignupStep5 />} />
          </Route>
        </Routes>
      }
    </Router>
  );
}

export default App;
