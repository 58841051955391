import React, { useEffect, useState } from 'react'
import images from '../images'
import { Modal } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import { Search } from './Header'

export function CustomModal(props) {
    return (
        <Modal
            size={props.modal.size}
            centered
            show={props.modal.show}
            onHide={props.handleClose}
            className='custom_modal'
        >
            <Modal.Body className="card custom_card border-0">
                {props.children}
            </Modal.Body>
        </Modal>
    )
}

export function ModalContent(props) {
    return (
        <div className='text-center py-3'>
            <img src={props.image} width={66} alt="" className={props.classname} />
            <div className='mt-3'>
                <p className='font14'>{props.msg}</p>
                <div className='my-4 mb-5'>
                    {props.content}
                </div>
                {props.showBtns ?
                    <div className=''>
                        {props.btns}
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export default function ManageCompany() {
    const navigate = useNavigate()
    const [children, setChildren] = useState({})
    const [step, setStep] = useState("")
    const [modal, setModal] = useState({
        size: "sm",
        show: false
    })
    const handleClose = () => {
        setModal({ ...modal, show: false })
    }
    const showEmailStep4 = () => {
        updateEmailStep4()
        setTimeout(() => {
            updateEmailStep5()
        }, 2500);
        setTimeout(() => {
            updateEmailStep6()
        }, 4500);
    }
    const showRemoveStep4 = () => {
        removeStep4()
        setTimeout(() => {
            removeStep5()
        }, 2500);
        setTimeout(() => {
            removeStep6()
        }, 4500);
    }

    const updateEmail = () => {
        setModal({ ...modal, show: true })
        setChildren(
            <ModalContent
                image={images?.help}
                msg={"Are you sure you want to update the following Email address for John Associates & Co?"}
                content={
                    <>
                        <p>Email</p>
                        <h5 className='font14 mt-1'>john.doe@gmail.com  </h5>
                    </>
                }
                btns={
                    <>
                        <button className='common_btn primary-bg' onClick={()=>updateEmailStep2("update")}>Yes</button>
                        <button className='ms-3' onClick={() => setModal({ ...modal, show: false })}><p>Cancel</p></button>
                    </>
                }
                showBtns={true}
            />
        )
    }
    const removeCompany = () => {
        setModal({ ...modal, show: true })
        setChildren(
            <ModalContent
                image={images?.delete_light}
                msg={""}
                content={
                    <>
                        <p className='text-dark font14'>Are you sure you want to Remove the following company?</p>
                        <h5 className='font16 my-4'>John Associates & Co  </h5>
                        <div className='noHover common_btn common_btn_filled d-block'>
                            <p>You won’t be able to retrieve this data anymore, as it would be removed permanently from your account</p>
                        </div>
                    </>
                }
                btns={
                    <>
                        <button className='common_btn primary-bg' onClick={()=>updateEmailStep2("")}>Yes</button>
                        <button className='ms-3' onClick={() => setModal({ ...modal, show: false })}><p>Cancel</p></button>
                    </>
                }
                showBtns={true}
            />
        )
    }
    const updateEmailStep2 = (type) => {
        setChildren(
            <ModalContent
                image={images?.encrypted}
                msg={"We have sent you an OTP to your email address john.doe@gmail.com for email updating verification"}
                content={
                    <div>
                        <input type='number' placeholder='Enter OTP' className='common_btn common_btn_filled font14 noHover form-control' maxLength={4} />
                    </div>
                }
                btns={
                    <>
                        <button className='common_btn primary-bg' onClick={type == 'update' ? updateEmailStep3 : showRemoveStep4}>Verify</button>
                    </>
                }
                showBtns={true}
            />
        )
    }
    const updateEmailStep3 = () => {
        setChildren(
            <ModalContent
                image={images?.mail}
                msg={"Enter your New Email address for John Associates & Co."}
                content={
                    <div>
                        <input type='email' placeholder='New Email Address' className='text-start common_btn common_btn_filled font14 noHover form-control' />
                    </div>
                }
                btns={
                    <>
                        <button className='common_btn primary-bg' onClick={showEmailStep4}>Save</button>
                    </>
                }
                showBtns={true}
            />
        )
    }
    const updateEmailStep4 = (type) => {
        setChildren(
            <ModalContent
                image={images?.loader}
                msg={"Please wait until we update your Email Address"}
                classname="loader_spin"
                content={""}
                showBtns={false}
            />
        )
    }
    const removeStep4 = (type) => {
        setChildren(
            <ModalContent
                image={images?.loader}
                msg={"Verifying..."}
                classname="loader_spin"
                content={""}
                showBtns={false}
            />
        )
    }
    const updateEmailStep5 = () => {
        setChildren(
            <ModalContent
                image={""}
                msg={""}
                classname=""
                content={
                    <>
                        <p className='font14 mb-0'>Your New Email address has been updated to </p>
                        <p className='font14 text-dark reg'>sujeet.karn@gmail.com </p>
                    </>}
                showBtns={false}
            />
        )
    }
    const removeStep5 = () => {
        setChildren(
            <ModalContent
                image={""}
                msg={""}
                classname=""
                content={
                    <>
                        <p className='font14 text-dark reg'>Your company John Associates & Co. has been Removed successfully.</p>
                    </>}
                showBtns={false}
            />
        )
    }
    
    const removeStep6 = () => {
        setChildren(
            <ModalContent
                image={images?.delete}
                msg={""}
                content={
                    <>
                        <p className='font14 text-dark reg'>Your company John Associates & Co. has been Removed successfully.</p>
                    </>
                }
                btns={
                    <>
                        <button className='common_btn primary-bg' onClick={() => setModal({ ...modal, show: false })}>Okay</button>
                    </>
                }
                showBtns={true}
            />
        )
    }
    const updateEmailStep6 = () => {
        setChildren(
            <ModalContent
                image={images?.beenhere}
                msg={""}
                content={
                    <>
                        <p className='font14 mb-0'>Your New Email address has been updated to </p>
                        <p className='font14 text-dark reg'>sujeet.karn@gmail.com </p>
                    </>
                }
                btns={
                    <>
                        <button className='common_btn primary-bg' onClick={() => setModal({ ...modal, show: false })}>Okay</button>
                    </>
                }
                showBtns={true}
            />
        )
    }


    return (
        <div className='device_logged_outer card border-0 p-3 custom_card'>
            <button onClick={() => navigate(-1)} className='font16 med text-start primary-text sbold' >
                <img className='me-2' src={images?.arrow_back} alt='' width={15} /> Manage Company
            </button>
            <div className='manage_company_outer mt-4'>
                <div className='row g-0'>
                    <div className='col-sm-4'>
                        <div className='aside_profile_img mb-3'>
                            <img src={images.profile} alt="" width={100} />
                        </div>
                        <h5 className='font16 reg'>John Doe</h5>
                        <p className='font12 mb-1'>john.doe@gmail.com</p>
                        <p className='font12 mb-2'>+91 9876543210</p>
                        <button className='primary-text' onClick={updateEmail}>Update Email
                            <svg className='ms-2' width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.0583 3.9845L0.513828 1.44003C0.413672 1.33987 0.361589 1.22128 0.357578 1.08426C0.353568 0.947241 0.405651 0.824642 0.513828 0.716465C0.622005 0.608288 0.742599 0.554199 0.875609 0.554199C1.00862 0.554199 1.12921 0.608288 1.23739 0.716465L4.01142 3.49051C4.08675 3.56582 4.14064 3.64235 4.17309 3.72007C4.20554 3.79779 4.22177 3.88593 4.22177 3.9845C4.22177 4.08306 4.20554 4.1712 4.17309 4.24892C4.14064 4.32665 4.08675 4.40317 4.01142 4.47848L1.23739 7.25253C1.13723 7.35268 1.01865 7.40477 0.881625 7.40878C0.744604 7.41279 0.622005 7.36071 0.513828 7.25253C0.405651 7.14435 0.351562 7.02376 0.351562 6.89075C0.351562 6.75774 0.405651 6.63714 0.513828 6.52896L3.0583 3.9845Z" fill="var(--primary)" />
                            </svg>

                        </button>
                        <hr />
                        <div className='recipent_search pe-0 pe-sm-4'>
                            <p className='med'>Your Companies</p>
                            <div className='my-2'>
                                <Search placeholder="Search Company" />
                            </div>
                            <div className='logout_btns'>
                                <button>
                                    <span className='recipent_badge'>
                                        <img src={images?.profile_logo} alt='' width={20} />
                                    </span>
                                    John & Associates Co.
                                </button>
                                <button>
                                    <span className='recipent_badge' style={{ backgroundColor: "#312496" }}>D</span>
                                    Devs & Designs
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-8'>
                        <div className='manage_company_detail'>
                            <div className='aside_profile_img mb-3'>
                                <img src={images.profile_logo} alt="" width={100} />
                            </div>
                            <button className='common_btn rounded'>
                                <svg width="10" height="10" className='me-2' viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.5 9.5V7.375L7.1 0.7875C7.2 0.695833 7.31042 0.625 7.43125 0.575C7.55208 0.525 7.67917 0.5 7.8125 0.5C7.94583 0.5 8.075 0.525 8.2 0.575C8.325 0.625 8.43333 0.7 8.525 0.8L9.2125 1.5C9.3125 1.59167 9.38542 1.7 9.43125 1.825C9.47708 1.95 9.5 2.075 9.5 2.2C9.5 2.33333 9.47708 2.46042 9.43125 2.58125C9.38542 2.70208 9.3125 2.8125 9.2125 2.9125L2.625 9.5H0.5ZM7.8 2.9L8.5 2.2L7.8 1.5L7.1 2.2L7.8 2.9Z" fill="#F15A29" />
                                </svg>
                                Edit Picture</button>
                            <div className='my-4'>
                                <button className='border-0 text-start noHover common_btn common_btn_filled w-100 d-flex justify-content-between'>
                                    <span className='font14'>
                                        <p className='font10'>Company Name</p>
                                        <span className='text-dark'>John Associates & Co.</span>
                                    </span>
                                    <p className='font10'>22/60</p>
                                </button>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <button className='common_btn primary-bg'>Save</button>
                                <button className='common_btn common_btn_filled text_danger border-0' onClick={removeCompany}>Remove Company</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal modal={modal} handleClose={handleClose} children={children} />
        </div>
    )
}
