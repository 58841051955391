import React, { useState } from 'react'
import PlainHeader from '../PlainHeader/PlainHeader'
import Footer from '../Footer/Footer'
import images from '../images'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';



export default function Signup() {
    let navigate = useNavigate();
    const [error, seterror] = useState(false)
    const [value, setvalue] = useState({
        companyName: ""
    }) 
    const handleChange = (e) => {
        setvalue({ [e.target.name]: e.target.value })
    }
    const submitCompanyName = (e) => {

        if (value.companyName === "") {
            seterror(true)
        } else {
            seterror(false) 
            navigate("/Signup/step-2")


        }

    }

    return (
        <>
            <PlainHeader />

            <div className='loginPageContentSection loginpage'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={images.signupPage1Img} className='d-block ' />
                        </div>
                        <div className="col-md-6">
                            <div className='loginSignUpPopRight'>
                                <div className='loginSignUpPopRight__top'>
                                    <h3 className='loginSignUpPopRight__title sbold'>Your Company</h3>
                                    <p className='font12 light'>Fill up your company details </p>
                                </div>
                                <Form.Group className="loginGwrkSpc__input" >
                                    <Form.Control type="text" placeholder="Your company details " name='companyName' value={value.companyName} onChange={handleChange} />
                                    {error && value.companyName === "" ?
                                        <Form.Control.Feedback type="invalid" className='d-block'>
                                            Please enter company details
                                        </Form.Control.Feedback> : null}

                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <button className='common_btn primary-bg w-100 bold py-2 ' onClick={submitCompanyName}> Next</button>
                                </Form.Group>





                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </ >
    )
}
