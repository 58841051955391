import React, { useEffect, useState } from 'react'
import images from '../images'
import { Search } from '../Common/Header'
import { useNavigate } from 'react-router-dom'
import { team } from '../Container'
import { CustomModal } from '../Common/ManageCompany'
import RemoveTeamMember from './RemoveTeamMember'
import _ from "underscore";
import { useSnackbar } from 'notistack'

export default function Settings() {
    const sortBy = [
        { label: "Sort by Alphabetic", icon: images?.sort_by_alpha, value: "Alphabetic" },
        { label: "Sort by Size", icon: images?.sort_filter_list, value: "Size" },
    ]
    const navigate = useNavigate()
    const [teamData, setTeamData] = useState(team)
    const [checked, setChecked] = useState([])
    const [selected, setSelected] = useState(team["A"][0] || {})
    const [children, setChildren] = useState({})
    const [allChecked, setallChecked] = useState(false)
    const [showDD, setShowDD] = useState("")
    const [showSidebar, setshowSidebar] = useState(false)
    const [modal, setModal] = useState({
        size: "md",
        show: false
    })
    const handleClose = () => {
        setModal({ ...modal, show: false })
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const fileOptionClick = () => { }

    const handleCheck = (e, data) => {
        let obj = { ...teamData }
        Object.keys(obj).map((keys) => {
            obj[keys].map((opt) => {
                if (opt?.email == data?.email) {
                    return opt["checked"] = e.target.checked
                }
            })
        })
        let arr = [...checked]
        if (e.target.checked) {
            if (arr.length > 0) {
                arr.map((x) => {
                    if (x.id !== data.id) {
                        arr.push(data)
                    }
                })
            } else {
                arr.push(data)
            }
        } else {
            arr = checked.filter((x) => x.id !== data.id)
        }
        setChecked(_.uniq(arr))
        setTeamData(obj)
        setallChecked(false)
    }

    const handleCheckAll = (e) => {
        let obj = { ...teamData }
        let arr = []
        Object.keys(obj).map((keys) => {
            obj[keys].map((opt) => {
                return opt["checked"] = e.target.checked
            })
        })
        if (e.target.checked) {
            Object.keys(obj).map((keys) => {
                obj[keys].map((opt) => {
                    arr.push(opt)
                })
            })
        }
        setallChecked(e.target.checked)
        setChecked(arr)
        setTeamData(obj)
    }
    const reset = () => {
        let obj = { ...teamData }
        let arr = []
        Object.keys(obj).map((keys) => {
            obj[keys].map((opt) => {
                return opt["checked"] = false
            })
        })
        setChecked(arr)
        setTeamData(obj)
        setallChecked(false)
    }

    const handleSubmit = () => {
        handleClose()
        enqueueSnackbar(`${checked.length} Members removed`, {
            variant: 'error'
        })
    }

    const showRemoveMember = () => {
        setModal({ ...modal, show: true })
        setChildren(
            <RemoveTeamMember data={checked} handleClose={handleClose} handleSubmit={handleSubmit} title={"Are you sure you want to remove the following Team Members?"} type={"remove"} />
        )
    }

    return (
        <div className='auth_container_fluid'>
            <div className='header_container manage_team_container'>
                <button className='aside_toggle' onClick={() => setshowSidebar(!showSidebar)}>
                    <img src={images?.toggle} alt="" />
                </button>
                <div className='row'>
                    <div className={`col-sm-4 auth_team_wrapper_aside manage_team_wrapper_aside ${showSidebar ? "show" : ""}`}>
                        <div className='auth_team_setting_aside card custom_card p-3'>
                            <div className='auth_team_left_header justify-content-between'>
                                <button className='font16 sbold primary-text mb-0 text-start' onClick={() => navigate(-1)}><img className='me-2' src={images?.arrow_back} alt='' width={15} /> Manage Team</button>
                                <button className='font12 sbold primary-text mb-0 text-start' onClick={() => navigate(-1)}><img className='me-1' src={images?.person_add} alt='' width={18} /> Add Team</button>
                            </div>
                            <div className='auth_team_left_header mt-3'>
                                <div className='d-flex w-100 align-items-center mt-sm-0 mt-2'>
                                    <div className='w-100 me-3'>
                                        <Search placeholder={"Search Team"} />
                                    </div>
                                    <div className='position-relative'>
                                        <button onClick={() => setShowDD("sort")}>
                                            <img src={images?.sort} width={16} alt="" />
                                        </button>
                                        {showDD == "sort" ?
                                            <>
                                                <div className='custom_dropdown_backdrop' onClick={() => setShowDD("")}></div>
                                                <div className='custom_dropdown_list_outer left' onClick={() => setShowDD("")}>
                                                    <div className='custom_dropdown_list'>
                                                        {sortBy.map((opt, i) => {
                                                            return (
                                                                <button key={i} onClick={() => fileOptionClick(opt)} className='font10'>
                                                                    {opt?.icon ?
                                                                        <span className='list_icon'>
                                                                            <img src={opt?.icon} alt="" className='me-2' />
                                                                        </span>
                                                                        : null
                                                                    }
                                                                    {opt?.label}
                                                                </button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className=''>
                                <div>
                                    <div className="row justify-content-between align-items-center">
                                        <label className='col-7 custom_checkbox font12 d-flex'>
                                            <span className='check_box me-2 ms-2'>
                                                <input type='checkbox' checked={allChecked} onChange={handleCheckAll} />
                                                <span></span>
                                            </span>
                                            <p>Select all
                                                <span className='mx-2 font20' style={{ lineHeight: 0, verticalAlign: "super", opacity: 0.5 }}>.</span>
                                                11 Team Members
                                            </p>
                                        </label>
                                        <div className='col-5 text-end'>
                                            <button style={{ opacity: checked.length ? 1 : 0, pointerEvents: checked.length ? "all" : "none" }} className='common_btn common_btn_filled text_danger border-0 rounded me-2' onClick={showRemoveMember}>
                                                <img src={images?.delete} width={12} className='me-1' alt="" /> <span className='d-sm-inline-block d-none'>Remove</span>
                                            </button>
                                            <button onClick={reset}>
                                                <img src={images?.refresh} width={16} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='auth_team_list2'>
                                    {
                                        Object.keys(teamData).map((keys) => {
                                            return (
                                                <div key={keys}>
                                                    <p className='team_group font12'><span>{keys}</span></p>
                                                    <div className='auth_team_group'>
                                                        {teamData[keys].map((data, i) => {
                                                            return (
                                                                <button className={`auth_team_group_item ${selected?.id == data?.id ? "selected" : ""}`} key={i}>
                                                                    <label className='custom_checkbox'>
                                                                        <span className='check_box me-3 ms-2'>
                                                                            <input type='checkbox' onChange={(e) => handleCheck(e, data)} checked={data?.checked} />
                                                                            <span></span>
                                                                        </span>
                                                                    </label>
                                                                    <div className='auth_team_badge' style={{ backgroundColor: data?.color }} onClick={() => {
                                                                        setSelected(data)
                                                                    }}>
                                                                        {data.name.split(" ")[0].charAt(0).toUpperCase()}{data.name.split(" ")[1] ? data.name.split(" ")[1].charAt(0).toUpperCase() : data.name.split(" ")[0].charAt(1).toUpperCase()}
                                                                    </div>
                                                                    <div className='w-100' onClick={() => {
                                                                        setSelected(data)
                                                                    }}>
                                                                        <div className='row align-items-end gx-0'>
                                                                            <div className='col-md-6'>
                                                                                <p className='font12 text-dark mb-0'>{data.name}</p>
                                                                                <p className='font12'>{data.position}</p>
                                                                            </div>
                                                                            <div className='col-md-6 text-start text-sm-end'>
                                                                                <p className='font10 mb-0'>{data.size}</p>
                                                                                <p className='font10'>{data.email}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='col-xl-8' onClick={() => setshowSidebar(false)}>
                        <div className='auth_team_setting_right card custom_card h-100'>
                            <div className='row'>
                                <div className='col-md-4 col-sm-5'>
                                    <div className='aside_profile_body text-start w-100 p-4'>
                                        <div className='aside_profile_img mb-3'>
                                            <div className='auth_team_badge large' style={{ backgroundColor: selected?.color }}>
                                                {selected.name.split(" ")[0].charAt(0).toUpperCase()}{selected.name.split(" ")[1] ? selected.name.split(" ")[1].charAt(0).toUpperCase() : selected.name.split(" ")[0].charAt(1).toUpperCase()}
                                            </div>
                                        </div>
                                        <h5 className='font16 reg'>{selected?.name}</h5>
                                        <p className='font12 mb-1'>{selected?.email}</p>
                                        <p className='font12 mb-1'>{selected?.phone}</p>
                                        <p className='font12'>Last Login {selected?.data?.lastLogin}</p>
                                        <hr />
                                        <div className='my-4'>
                                            <div className=''>
                                                <p className='font12 primary-text'>Storage Used</p>
                                                <p className='font12 mb-3'><span className='text-dark'>{selected?.size}</span> used / 690.0GB</p>
                                            </div>
                                            <div className='storage_bar_outer'>
                                                <span style={{ width: "50%", backgroundColor: "#FF7020" }}></span>
                                                <span style={{ width: "20%", backgroundColor: "#007BFF" }}></span>
                                            </div>
                                            <div className='storage_values'>
                                                <p className='font12 text-dark'>
                                                    <span><span style={{ backgroundColor: "#007BFF" }} className='storage_value_dot'></span>Mail</span>
                                                    <span>{selected?.mail}</span>
                                                </p>
                                                <p className='font12 text-dark'>
                                                    <span><span style={{ backgroundColor: "#FF7020" }} className='storage_value_dot'></span>File</span>
                                                    <span>{selected?.file}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='mail_read_more_btn'>
                                            <button className='common_btn rounded primary-text'>
                                                <img src={images?.open_in_new} alt="" className='me-2' />Show More</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8 col-sm-7'>
                                    <div className='recipent_search p-4'>
                                        <h5 className='font16 med'>{selected?.name ? `${selected?.name.split(" ")[0]}'s Recipients` : ""}</h5>
                                        <p className=''>12 Recipients</p>
                                        <div className='auth_team_left_header my-3'>
                                            <div className='d-flex w-100 align-items-center mt-sm-0 mt-2'>
                                                <div className='w-100 me-3'>
                                                    <Search placeholder="Search Recipients" />
                                                </div>
                                                <div className='position-relative'>
                                                    <button onClick={() => setShowDD("sort2")}>
                                                        <img src={images?.sort} width={16} alt="" />
                                                    </button>
                                                    {showDD == "sort2" ?
                                                        <>
                                                            <div className='custom_dropdown_backdrop' onClick={() => setShowDD("")}></div>
                                                            <div className='custom_dropdown_list_outer right' onClick={() => setShowDD("")}>
                                                                <div className='custom_dropdown_list'>
                                                                    {sortBy.map((opt, i) => {
                                                                        return (
                                                                            <button key={i} onClick={() => fileOptionClick(opt)} className='font10'>
                                                                                {opt?.icon ?
                                                                                    <span className='list_icon'>
                                                                                        <img src={opt?.icon} alt="" className='me-2' />
                                                                                    </span>
                                                                                    : null
                                                                                }
                                                                                {opt?.label}
                                                                            </button>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='logout_btns'>
                                            {selected?.data.mails.map((opt, i) => {
                                                return (
                                                    <button key={i}>
                                                        <span className='recipent_badge' style={{ backgroundColor: opt?.sender?.color }}>{opt?.sender?.name.split(" ")[0].charAt(0).toUpperCase()}</span>
                                                        {opt?.sender?.name}
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal modal={modal} handleClose={handleClose} children={children} />
        </div>
    )
}
