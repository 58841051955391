import React, { useState } from 'react'
import images from '../images'
import { Modal } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'

export default function DeviceLogged() {
    const navigate = useNavigate()
    const device = [
        { name: "John’s Macbook", city: "Mumbai, maharashtra" },
        { name: "John’s iPhone 14", city: "Mumbai, maharashtra" },
    ]
    const [active, setActive] = useState({})
    const [modal, setModal] = useState({
        size: "sm",
        show: false
    })
    const [step, setStep] = useState("1")
    const handleClose = () => {
        setModal({ ...modal, show: false })
    }
    const yesClick = () => {
        setStep("2")
        setTimeout(() => {
            setStep("3")
        }, 3000);
    }
    return (
        <div className='device_logged_outer card border-0 p-3 custom_card'>
            <button onClick={()=>navigate(-1)} className='font16 med text-start primary-text sbold' >
                <img className='me-2' src={images?.arrow_back} alt='' width={15} /> Device Logged in
            </button>
            <hr />
            <div className='device_logged_list'>
                {device.map((opt, i) => {
                    return (
                        <div className=' mb-3' key={opt.name}>
                            {i > 0 ?
                                <hr />
                                : null
                            }
                            <div className='row justify-content-between'>
                                <div className='col-8'>
                                    <p className='text-dark mb-0'>{opt?.name}</p>
                                    <p className='mb-0'>{opt?.city}</p>
                                </div>
                                <div className='col-4 text-end'>
                                    <button className='common_btn common_btn_filled rounded' onClick={() => {
                                        setActive(opt)
                                        setModal({ ...modal, show: true })
                                    }}>Logout</button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Modal
                size={modal.size}
                centered
                show={modal.show}
                onHide={handleClose}
                className='custom_modal'
            >
                <Modal.Body className="card custom_card border-0">
                    <div className='text-center py-3'>
                        {step == "1" ?
                            <div>
                                <img src={images?.help} width={66} alt="" />
                                <div className='mt-3'>
                                    <p>Are you sure you want to Logout from the following Device?</p>
                                    <div className='my-4'>
                                        <p>Device</p>
                                        <h5 className='font14 mt-1'>{active?.name}</h5>
                                    </div>
                                    <div className=''>
                                        <button className='common_btn primary-bg' onClick={yesClick}>Yes</button>
                                        <button className='ms-3' onClick={() => {
                                            setActive({})
                                            setModal({ ...modal, show: false })
                                        }}><p>Cancel</p></button>
                                    </div>
                                </div>
                            </div>
                            :
                            step == "2" ?
                                <div>
                                    <img src={images?.loader} width={66} alt="" className='loader_spin' />
                                    <div className='mt-3'>
                                        <p>Please wait until we Logout from this Device</p>
                                    </div>
                                </div>
                                :
                                <div>
                                    <img src={images?.beenhere} width={66} alt="" />
                                    <div className='my-4'>
                                        <p>Device Logged out from</p>
                                        <h5 className='font14 mt-1'>{active?.name}</h5>
                                    </div>
                                    <div className=''>
                                        <button className='common_btn primary-bg' onClick={() => {
                                            setActive({})
                                            setModal({ ...modal, show: false })
                                        }}><p>Okay</p></button>
                                    </div>
                                </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
